import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { RoutingService } from './routing.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import * as moment from 'moment';
import { Observable, forkJoin, throwError, of } from 'rxjs';
import { catchError, map, shareReplay, mergeMap, timeout } from 'rxjs/operators';

/**
* This service is the most impostant basically it request all the data relative to the sentiment
*/

@Injectable()
export class YukkApi {

  apiurl: string;
  apiurlQuant: string;
  apiurlMetadata: string;
  paramsForAllRequests = ['from', 'to', 'lang', 'feed', 'categories', 'continents', 'countries', 'ranks'];
  project: string;

  /**
  * constructor
  */
  constructor(
    private http: HttpClient,
    public routing: RoutingService,
    private router: Router,
    public auth: AuthService,
    public config: ConfigService
  ) {

    this.project = this.config.appConfig.routing.apiproj;
    this.apiurl = this.config.appConfig.urlDataApi;
    this.apiurlQuant = this.config.appConfig.urlQuantApi;
    this.apiurlMetadata = this.config.appConfig.urlMetadataApi;

  }

  // ERROR ---------------------------------------------------------------------

  /**
  * console error of the request
  */
  private handleError(error: HttpErrorResponse) {
    if (error) {
      if (error.error instanceof Error) {
        console.error('An error occurred:', error.error.message);
      } else if (error.error) {
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
        if (error.status === 401) {
          this.router.navigate(['/login']);
        }
      }
    }
    return throwError('Something bad happened please try again later.');
  }

  /**
  * here we start the request based on the url path
  */
  iRequest(iparams, paramsArg, typeArg) {

    if ((iparams.changeDataApi === true) || (iparams.changeDataApi === 'true')) {
      // this.apiurl = 'https://api.yukkalab.com/api-data/7-2';
    }

    const tradingEndpoints = [
      'bull_bear_score_ts',
      'bull_bear_score_ts_itemized',
      'score_ts_industry_bench_itemized',
      'score_ts_industry_bench'
    ];

    const v2Endpoints = [
       'sub_events',
       'sub_events_itemized',
       'events_ts',
       'related_entities',
       'related_events_participants',
       'score_ts_itemized',
       'score_ts_top_events',
       'quotient_ts_itemized',
       'news',
       'newsview'
    ];

    let type = typeArg;

    let requestUrlBase = this.apiurl + '/api';
    if (tradingEndpoints.includes(type)) {
      requestUrlBase = this.apiurlQuant + '/trading';
    } else if (v2Endpoints.includes(type)) {
      requestUrlBase = this.apiurl + '/v2/api';
    }

    if (type === 'sub_events') {
      if (iparams.event_ids) {
        delete paramsArg['event_ids'];
      }
      type = 'events?event_type=sub_event';
    } else if (type === 'sub_events_itemized') {
      if (iparams.event_ids) {
        type = 'events_itemized';
      } else {
        type = 'events_itemized?event_type=sub_event';
      }
    }

    let request: any;
    const params = paramsArg;
    if (this.routing.isChart2() && (type === 'multi_trend_radar')) {
      params['no_binning'] = true;
    }

    if (type === 'score_ts_weights') {
      const requestUrl = `${requestUrlBase}/all/all/score_ts_weights`;
      request = this.http.get(requestUrl, this.getOption(params));
    } else if (((type === 'news') || (type === 'stories')) && (iparams.id === 'icb') && (iparams.type === 'hierarchy')) {
      const requestUrl = `${requestUrlBase}/portfolio/${type}`;
      request = this.getHierarchyInfo('icb').pipe(mergeMap(resArg => {
        const res = JSON.parse(JSON.stringify(resArg));
        const industries = [];
        res.treemap.children.forEach((industry, index) => {
          industries.push(industry.entity.compound_key);
        });
        return this.http.post(requestUrl, industries, this.getOption(params));
      }));
    } else if ((type === 'quotient_ts_itemized') && iparams.custom_payload) {
      const requestUrl = `${requestUrlBase}/portfolio/${type}`;
      request = this.http.post(requestUrl, iparams.custom_payload, this.getOption(params));
    } else if (((type === 'news') || (type === 'stories') || (type === 'semantic_search')) && iparams.newsfeedFromQuery && this.auth.queryForNewsfeed) {
      const requestUrl = `${requestUrlBase}/search_query/${type}`;
      request = this.http.post(requestUrl, this.auth.queryForNewsfeed.query.query, this.getOption(params)).pipe(map((res) => res), catchError(error => of(undefined)));
    } else if (type === 'newsview') {
      if (this.routing.isFolio() && !iparams.portfolioId) {
        const requestUrl = `${requestUrlBase}/all/all/news_item/${iparams.newsId}`;
        request = this.http.get(requestUrl, this.getOption(params));
      } else if (this.routing.isQuery() || this.routing.isSearch()) {
        const requestUrl = `${requestUrlBase}/search_query/news_item/${iparams.newsId}`;
        // request = this.http.get(requestUrl, this.getOption(params));
        request = this.http.post(requestUrl, this.auth.query.query, this.getOption(params));
      } else if (this.routing.isMarket() || iparams.portfolioId) {
        let requestUrl = `${requestUrlBase}/${iparams['type']}/${iparams['id']}/news_item/${iparams.newsId}`;
        if ((iparams.id === 'icb') && (iparams.type === 'hierarchy')) {
          requestUrl = `${requestUrlBase}/all/all/news_item/${iparams.newsId}`;
        }
        request = this.http.get(requestUrl, this.getOption(params));
      } else if (this.routing.isNewsletter()) {
        if ((iparams['type'] === 'portfolio') || (iparams['type'] === 'search_query')) {
          const requestUrl = `${requestUrlBase}/${iparams['type']}/news_item/${iparams.newsId}`;
          request = this.http.post(requestUrl, iparams['payload'], this.getOption(params));
        } else {
          const requestUrl = `${requestUrlBase}/${iparams['type']}/${iparams['id']}/news_item/${iparams.newsId}`;
          request = this.http.get(requestUrl, this.getOption(params));
        }
      } else {
        const options = this.getOption(params);
        const requestUrl = `${requestUrlBase}/all/all/news_item/${iparams.newsId}`;
        request = this.http.get(requestUrl, options);
      }
    } else if (iparams.fromAlertsSetup) {
      const requestUrl = `${requestUrlBase}/${iparams['type']}/${iparams['id']}/${type}`;
      request = this.http.get(requestUrl, this.getOption(params));
    } else if (iparams.fromHomeGuard && iparams.array) {
      if (this.auth.folios) {
        const requestArray = [];
        const requestUrl = `${requestUrlBase}/portfolio/${type}`;
        this.auth.folios.forEach(portfolio => {
          const req = this.http.post(requestUrl, portfolio.content, this.getOption(params));
          requestArray.push(req);
        });
        request = forkJoin(requestArray);
      } else {
        request = of([]);
      }
    } else if (iparams.iquery) {
      const requestUrl = `${requestUrlBase}/search_query/${type}`;
      request = this.http.post(requestUrl, iparams.iquery, this.getOption(params)).pipe(map((res) => res), catchError(error => of(undefined)));
    } else if (this.routing.isScanner() && iparams.tag && !iparams.type && !iparams.scoreForTabs && !iparams.itemized) {
      const tagType = iparams.tag.split(':')[1];
      const tagId = iparams.tag.split(':')[2];
      const requestUrl = `${requestUrlBase}/${tagType}/${tagId}/${type}`;
      request = this.http.get(requestUrl, this.getOption(params)).pipe(map((res) => res), catchError(error => of({})));
    } else if (((this.routing.isFolio() || this.routing.isScanner()) && (iparams.scorePortfolio || iparams.scoreForPortfolio || iparams.scoreScanner)) || iparams.usePortfolio) {
      if (iparams.scoreForPortfolio || iparams.usePortfolio) {
        const requestUrl = `${requestUrlBase}/portfolio/${type}`;
        let body;
        if (iparams.custom_payload) {
          body = iparams.custom_payload;
        } else {
          body = this.auth.folio ? this.auth.folio.content : [];
        }
        request = this.http.post(requestUrl, body, this.getOption(params));
      } else {
        const requestUrl = `${requestUrlBase}/${iparams['type']}/${iparams['id']}/${type}`;
        request = this.http.get(requestUrl, this.getOption(params)).pipe(map((res) => res), catchError(error => of({})));
      }
    } else if (this.routing.isMarket() || iparams.portfolioId) {
      if (iparams.portfolio) {
        const requestUrl = `${requestUrlBase}/portfolio/${type}`;
        if (this.auth.folios) {
          request = this.auth.folios.filter(portfolio => {
            return portfolio.uid === iparams.portfolioId;
          }).map(item => {
            const payload = item.content;
            return this.http.post(requestUrl, payload, this.getOption(params)).pipe(map((res) => res), catchError(error => of(undefined)));
          })[0];
        } else {
          request = of({});
        }
      } else if (this.auth.folio && this.auth.folio.marketPortfolio) {
        const requestUrl = `${requestUrlBase}/portfolio/${type}`;
        const payload = this.auth.folio.content;
        return this.http.post(requestUrl, payload, this.getOption(params)).pipe(map((res) => res), catchError(error => of(undefined)));
      } else {
        const requestUrl = `${requestUrlBase}/${iparams['type']}/${iparams['id']}/${type}`;
        request = this.http.get(requestUrl, this.getOption(params));
      }
    } else if (this.routing.isFolio() && !iparams.portfolioId) {
      if (iparams.requestEntity) {
        const requestUrl = `${requestUrlBase}/${iparams['type']}/${iparams['id']}/${type}`;
        request = this.http.get(requestUrl, this.getOption(params));
      } else if (iparams.array === true) {
        if (this.auth.folios) {
          const requestArray = [];
          const requestUrl = `${requestUrlBase}/portfolio/${type}`;
          this.auth.folios.forEach(portfolio => {
            const req = this.http.post(requestUrl, portfolio.content, this.getOption(params));
            requestArray.push(req);
          });
          request = forkJoin(requestArray);
        } else {
          request = of([]);
        }
      } else {
        const requestUrl = `${requestUrlBase}/portfolio/${type}`;
        let body = [];
        if (this.routing.isChart2() && this.auth.payloadEntities.length) {
          body = this.auth.payloadEntities;
        } else {
          body = this.auth.folio ? this.auth.folio.content : [];
        }
        request = this.http.post(requestUrl, body, this.getOption(params));
      }
    } else if (this.routing.isQuery() || this.routing.isSearch()) {
      let requestUrl = `${requestUrlBase}/search_query/${type}`;
      if (type === 'multi_trend_radar') {
        requestUrl = `${requestUrlBase}/search_query/quotient_ts`;
      }
      if (iparams.array === true) {
        if (this.auth.querys) {
          const requestArray = [];
          this.auth.querys.forEach(query => {
            const payload = {
              operator: 'or',
              filters: [],
              groups: query.content.map(item => {
                return item.query;
              })
            };
            const req = this.http.post(requestUrl, payload, this.getOption(params)).pipe(map((res) => res), catchError(error => of(undefined)));
            requestArray.push(req);
          });
          request = forkJoin(requestArray);
        } else {
          request = of([]);
        }
      } else if (iparams.collection && (iparams.id !== iparams.groupId)) {
        if (this.auth.querys) {
          request = this.auth.querys.filter(collection => {
            return collection.uid === iparams.groupId;
          }).map(item => {
              const payload = {
                operator: 'or',
                filters: [],
                groups: item.content.map(item2 => {
                  return item2.query;
                })
              };
              return this.http.post(requestUrl, payload, this.getOption(params)).pipe(map((res) => res), catchError(error => of(undefined)));
          })[0];
        } else {
          request = of([]);
        }
      } else if ( this.auth.iquery && this.routing.isQuery() && (this.routing.isChart2() || this.routing.isRadar() || this.routing.isParticipants()) && ((type === 'multi_trend_radar') || (type === 'related_events_participants'))) {
        const requestArray = [];
        const mainReq = this.http.post(requestUrl, this.auth.query.query, this.getOption(params)).pipe(map((res) => res), catchError(error => of(undefined)));
        requestArray.push(mainReq);
        this.auth.iquery.forEach(item => {
          const req = this.http.post(requestUrl, item.query, this.getOption(params)).pipe(map((res) => res), catchError(error => of(undefined)));
          requestArray.push(req);
        });
        request = forkJoin(requestArray);
      } else {
        request = this.http.post(requestUrl, this.auth.query.query, this.getOption(params)).pipe(map((res) => res), catchError(error => this.handleError(error)));
      }
    } else if (this.routing.isWidget()) {
      if ((location.pathname.split('/')[2] === 'portfolio' || iparams['type'] === 'portfolio') && !iparams.portfolioId) {
        const requestUrl = `${requestUrlBase}/portfolio/${type}`;
        request = this.http.post(requestUrl, this.auth.folio.content, this.getOption(params));
      } else if (location.pathname.split('/')[2] === 'query') {
        const requestUrl = `${requestUrlBase}/search_query/${type}`;
        request = this.http.post(requestUrl, this.auth.query.query, this.getOption(params));
      } else if (iparams.isin) {
        const requestUrl = `${requestUrlBase}/isin/${iparams['isin']}/${type}`;
        request = this.http.get(requestUrl, this.getOption(params));
      } else if (iparams.type && (iparams.type === 'search_query') && iparams.id && this.auth && this.auth.iquery) {
        const requestUrl = `${requestUrlBase}/search_query/${type}`;
        const query = this.auth.iquery.filter(item => {
          return item.uid === iparams.id;
        })[0]?.query;
        request = this.http.post(requestUrl, query, this.getOption(params));
      } else {
        const requestUrl = `${requestUrlBase}/${iparams['type']}/${iparams['id']}/${type}`;
        request = this.http.get(requestUrl, this.getOption(params));
      }
    } else if (this.routing.isNewsletter()) {

      if ((type === 'news') || (type === 'stories') || (type === 'semantic_search')) {

        if ((iparams['type'] === 'portfolio') || (iparams['type'] === 'search_query')) {
          const requestUrl = `${requestUrlBase}/${iparams['type']}/${type}`;
          request = this.http.post(requestUrl, iparams['payload'], this.getOption(params));
        } else {
          const requestUrl = `${requestUrlBase}/${iparams['type']}/${iparams['id']}/${type}`;
          request = this.http.get(requestUrl, this.getOption(params));
        }

      } else {
        const requestUrl = `${requestUrlBase}/${iparams['type']}/${iparams['id']}/${type}`;
        request = this.http.get(requestUrl, this.getOption(params));
      }

    } else if (this.routing.isHomepage()) {

      if (type === 'news') {
        const requestUrl = `${requestUrlBase}/portfolio/${type}`;
        let body = [];
        body = (this.auth.folio && this.auth.folio.contentTop) ? this.auth.folio.contentTop : [];
        request = this.http.post(requestUrl, body, this.getOption(params));
      } else if (type === 'events?event_type=sub_event') {
        if (iparams.type) {
          const requestUrl = `${requestUrlBase}/${iparams['type']}/${iparams['id']}/events?event_type=sub_event`;
          request = this.http.get(requestUrl, this.getOption(params));
        } else {
          const requestUrl = `${requestUrlBase}/portfolio/events?event_type=sub_event`;
          let body = [];
          body = (this.auth.folio && this.auth.folio.content) ? this.auth.folio.content : [];
          request = this.http.post(requestUrl, body, this.getOption(params));
        }
      } else {
        const requestUrl = `${requestUrlBase}/portfolio/${type}`;
        let body = [];
        body = (this.auth.folio && this.auth.folio.content) ? this.auth.folio.content : [];
        request = this.http.post(requestUrl, body, this.getOption(params));
      }

    } else if (this.routing.isHot()) {

      if (iparams.tag) {
        const tagType = iparams.tag.split(':')[1];
        const tagId = iparams.tag.split(':')[2];
        const requestUrl = `${requestUrlBase}/${tagType}/${tagId}/${type}`;
        request = this.http.get(requestUrl, this.getOption(params)).pipe(map((res) => res), catchError(error => of({})));
      } else if (iparams.score_type) {
        const tagType = iparams.type;
        const tagId = iparams.id;
        const requestUrl = `${requestUrlBase}/${tagType}/${tagId}/${type}`;
        request = this.http.get(requestUrl, this.getOption(params)).pipe(map((res) => res), catchError(error => of({})));
      } else {
        const requestUrl = `${requestUrlBase}/portfolio/${type}`;
        let body = [];
        body = this.auth.internalCockpitSettings.portfolioInvestLab.content;
        request = this.http.post(requestUrl, body, this.getOption(params)).pipe(map((res) => res), catchError(error => of({})));
      }

    } else if (this.routing.isPath('stats')) {
      if (type === 'treemap') {
        const id = iparams.id;
        const requestUrl = `${requestUrlBase}/country/${id}/${type}`;
        request = this.http.get(requestUrl, this.getOption(params)).pipe(map((res) => res), catchError(error => of({})));
      } else if ((type === 'related_entities') || (type === 'events_itemized?event_type=sub_event') || (type === 'events_itemized')) {
        const requestUrl = `${requestUrlBase}/portfolio/${type}`;
        const body = iparams.body;
        request = this.http.post(requestUrl, body, this.getOption(params));
      }
    }

    this.apiurl = this.config.appConfig.urlDataApi;

    return request
      // .pipe(catchError(err => of(undefined)))
      .pipe(catchError(error => this.handleError(error)));
  }

  /**
  * setup the project of the enviroment
  */
  setProject(project) {
    this.project = project;
  }

  /**
  * default parameters 4 the request
  */
  myParams(iparams, paramsToBeSent) {
    const params = {};
    params['project_id'] = this.project;
    if (paramsToBeSent.includes('lang')) {
      if (iparams.lang && iparams.lang !== 'multi') { params['lang'] = iparams.lang; }
    }
    let time = '7';
    if (this.routing.isReader() || this.routing.isStoryReader()) {
      time = '90';
    }
    if (iparams.time) {
      time = iparams.time;
    }
    const fromto = this.myFromTo(time);
    if (paramsToBeSent.includes('from')) {
      params['from'] = fromto.date1;
    }
    if (paramsToBeSent.includes('to')) {
      params['to'] = fromto.date2;
    }
    if (paramsToBeSent.includes('period')) {
      if (iparams.period) {
        params['period'] = iparams.period;
      } else {
        params['period'] = 7;
      }
    }
    if (paramsToBeSent.includes('feed')) {
      if (iparams.feed) { params['feed_types'] = iparams.feed; }
    }
    if (paramsToBeSent.includes('categories')) {
      if (iparams.categories) { params['exclude_document_category_filter'] = iparams.categories; }
    }
    if (paramsToBeSent.includes('continents')) {
      if (iparams.continents) { params['source_continents'] = iparams.continents; }
    }
    if (paramsToBeSent.includes('countries')) {
      if (iparams.countries) { params['source_countries'] = iparams.countries; }
    }
    if (paramsToBeSent.includes('ranks')) {
      if (iparams.ranks) { params['source_ranks'] = iparams.ranks; }
    }
    if (paramsToBeSent.includes('event_ids')) {
      if (iparams.event_ids) { params['event_ids'] = iparams.event_ids; }
    }
    if (paramsToBeSent.includes('tag')) {
      if (iparams.tag && (iparams.tag.split(':').length === 3)) {
        params['tag'] = iparams.tag.split('|')[0];
      }
    }
    if (paramsToBeSent.includes('participant')) {
      if (iparams.participant) {
        params['with_contained_events_participant'] = iparams.participant;
      }
    }
    if (paramsToBeSent.includes('with_top_events')) {
      if (iparams.with_top_events) {
        params['with_top_events'] = iparams.with_top_events;
      }
    }
    if (paramsToBeSent.includes('with_all_events')) {
      if (iparams.with_all_events) {
        params['n_events'] = -1;
      }
    }
    if (paramsToBeSent.includes('temporality')) {
      if (iparams.temporality && (iparams.temporality === 'na')) {
        params['temporality'] = 'NONE,UNKNOWN';
      } else if (iparams.temporality) {
        params['temporality'] = iparams.temporality;
      }
    }
    if (paramsToBeSent.includes('factuality')) {
      if (iparams.factuality && (iparams.factuality === 'na')) {
        params['factuality'] = 'NONE,UNKNOWN';
      } else if (iparams.factuality && (iparams.factuality === 'fact')) {
        params['factuality'] = 'FACT,COUNTERFACT';
      } else if (iparams.factuality && (iparams.factuality === 'probable')) {
        params['factuality'] = 'PROBABLE,COUNTERPROBABLE';
      } else if (iparams.factuality && (iparams.factuality === 'possible')) {
        params['factuality'] = 'POSSIBLE,COUNTERPOSSIBLE';
      }
    }
    return params;
  }


  /**
  * return from, to and period from the time parameter
  */
  myFromTo(value) {
    if (!isNaN(value)) {
      const daysToBeIncluded = ((value !== 1) && (value !== 2) && (value !== '1') && (value !== '2')) ? value : (value - 1);
      const date1 = moment().subtract(daysToBeIncluded, 'days').format('YYYY-MM-DD');
      const date2 = moment().add(1, 'days').format('YYYY-MM-DD');
      const period = parseInt(value, null);
      const time = { date1, date2, period };
      return time;
    } else {
      const date = value.split('--');
      const date1 = moment(date[0]).format('YYYY-MM-DD');
      const date2 = moment(date[1]).add(1, 'days').format('YYYY-MM-DD');
      const a = moment(date[0]);
      const b = moment(date[1]);
      const period = - a.diff(b, 'days');
      const time = { date1, date2, period };
      return time;
    }
  }

  /**
  * return from, to and period from the time parameter
  */
  chartFromTo(value) {
    if (!isNaN(value)) {
      const daysToBeIncluded = ((value !== 1) && (value !== 2) && (value !== '1') && (value !== '2')) ? value : (value - 1);
      const date1 = moment().subtract(daysToBeIncluded, 'days').format('YYYY-MM-DD');
      const date2 = moment().format('YYYY-MM-DD');
      const period = parseInt(value, null);
      const time = { date1, date2, period };
      return time;
    } else {
      const date = value.split('--');
      const date1 = moment(date[0]).format('YYYY-MM-DD');
      const date2 = moment(date[1]).format('YYYY-MM-DD');
      const a = moment(date[0]);
      const b = moment(date[1]);
      const period = - a.diff(b, 'days');
      const time = { date1, date2, period };
      return time;
    }
  }

  /**
  * header of the request
  */
  getOption(params) {

    const startDay = this.config.appConfig.setup.startDate;

    if (params && params['from'] && (params['from'] < startDay)) {
      params['from'] = this.myFromTo(this.config.appConfig.setup.startDate).date1;
    }

    if (params && params['to'] && (params['to'] < startDay)) {
      params['to'] = moment(this.myFromTo(this.config.appConfig.setup.startDate).date1).add(1, 'days').format('YYYY-MM-DD');
    }

    if (params && params['from'] && params['to'] && !params['izoom']) {
      const days = moment(params['to']).diff(moment(params['from']), 'days');
      const maxDays = this.auth.internalCockpitSettings.maxTimeframe;
      if ((!this.routing.isDev()) && (days > maxDays)) {
        params['from'] = moment(params['to']).subtract(maxDays, 'days').format('YYYY-MM-DD');
      }
    }

    if (params && params['izoom']) {
      delete params['izoom'];
    }

    let token = null;
    if (params && params.jwt) {
      if (this.config.appConfig.setup.useStorage) {
        token = (!this.routing.isReader() && !this.routing.isStoryReader()) ? localStorage.getItem('token') : params.jwt;
      } else {
        token = params.jwt;
      }
    } else {
      if (this.config.appConfig.setup.useStorage) {
        token = localStorage.getItem('token');
      }
    }
    if (params && params.jwt) {
      delete params['jwt'];
    }

    // changes needed to reflect FastAPI migration
    if (params && params['to']) {
      params['date_to'] = params['to'];
      delete params['to'];
    }
    if (params && params['from']) {
      params['date_from'] = params['from'];
      delete params['from'];
    }

    if (!token && this.auth.token) {
      token = this.auth.token;
    }

    // adding dynamic headers
    const headersObject = {
      'Authorization': 'Bearer ' + token,
      'Accept': 'application/json'
    };
    if (params && params.hasOwnProperty('headerIncludeNewsIds')) {
      headersObject['Include-News-Ids'] = params['headerIncludeNewsIds'];
      delete params['headerIncludeNewsIds'];
    }
    if (params && params.hasOwnProperty('headerExcludeNewsIds')) {
      headersObject['Exclude-News-Ids'] = params['headerExcludeNewsIds'];
      delete params['headerExcludeNewsIds'];
    }

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if ((typeof value === 'string') || (value instanceof String)) {
          params[key] = value.split(',');
        }
      });
    }

    return {
      params: params,
      headers: new HttpHeaders(headersObject)
    };
  }

  /**
  * search only entities
  */
  search(value, iparams): Observable<any> {
    const paramsToBeSent = [...this.paramsForAllRequests];
    // let params = {};
    // if (iparams) { params = this.myParams(iparams, paramsToBeSent); }
    // params['size'] = '50';
    // params['q'] = value;
    const qparam = encodeURIComponent(value);
    const size = '50';
    const options = this.getOption(null);
    const requestUrl = `${this.apiurlMetadata}/search/entity_search?q=${qparam}&size=${size}`;
    return this.http.get(requestUrl, options).pipe(mergeMap(resArg => {
      const res = JSON.parse(JSON.stringify(resArg));
      const portfolio = [];
      // @ts-ignore
      res['hits'].forEach(item => {
        const compound_key = item.entity.type + ':' + item.entity.alpha_id;
        portfolio.push(compound_key);
      });
      return this.newsCountItemized(iparams, portfolio).pipe(map(resArg2 => {
        const res2 = JSON.parse(JSON.stringify(resArg2));
        const countsObject = {};
        res2.result.forEach(item => {
          countsObject[item.entity_info.compound_key] = item.count;
        });
        const entitiesWithCounts = [];
        // @ts-ignore
        res['hits'].forEach(item => {
          const compound_key = item.entity.type + ':' + item.entity.alpha_id;
          const entity = Object.assign({}, item, {
            count: countsObject[compound_key]
          });
          if ((entity.entity.type === 'company') || (entity.entity.type === 'organization') || (entity.entity.type === 'commodity') || (entity.count && (entity.count > 0))) {
            entitiesWithCounts.push(entity);
          }
          // entitiesWithCounts.push(entity);
        });
        return {
          hits: entitiesWithCounts
        };
      }));
    }));
  }

  /**
  * search entity, source, events etc... - with counts
  */
  search_meta(value, iparams): Observable<any> {
    const paramsToBeSent = ['period', ...this.paramsForAllRequests];
    // let params = {};
    // if (iparams) { params = this.myParams(iparams, paramsToBeSent); }
    // params['size'] = '50';
    // params['q'] = value;
    const qparam = encodeURIComponent(value);
    const size = '50';
    const options = this.getOption(null);
    const requestUrlMeta = `${this.apiurlMetadata}/search/entity_search?q=${qparam}&size=${size}`;
    const requestUrlSource = `${this.apiurl}/search/source?q=${qparam}&size=${size}`;
    return forkJoin([this.http.get(requestUrlMeta, options), this.http.get(requestUrlSource, options)]).pipe(mergeMap(resArg => {
      const res = JSON.parse(JSON.stringify(resArg[0]));
      const resSources = JSON.parse(JSON.stringify(resArg[1]));
      const portfolio = [];
      // @ts-ignore
      res['hits'].forEach(item => {
        const compound_key = item.entity.type + ':' + item.entity.alpha_id;
        portfolio.push(compound_key);
      });
      return this.newsCountItemized(iparams, portfolio).pipe(map(resArg2 => {
        const res2 = JSON.parse(JSON.stringify(resArg2));
        const countsObject = {};
        res2.result.forEach(item => {
          countsObject[item.entity_info.compound_key] = item.count;
        });
        const entitiesWithCounts = [];
        // @ts-ignore
        res['hits'].forEach(item => {
          const compound_key = item.entity.type + ':' + item.entity.alpha_id;
          const entity = Object.assign({}, item, {
            count: countsObject[compound_key]
          });
          if ((entity.entity.type === 'company') || (entity.entity.type === 'organization') || (entity.entity.type === 'commodity') || (entity.count && (entity.count > 0))) {
            entitiesWithCounts.push(entity);
          }
        });
        const urlSources = [];
        resSources['source_prefix_search']['hits'].forEach(item => {
          if (item.url) {
            urlSources.push(item);
          }
        });
        return Object.assign({}, res, {
          entity_prefix_search: {
            hits: entitiesWithCounts
          },
          source_prefix_search: {
            hits: urlSources
          }
        });
      }));
    }));
  }

  /**
   * search entity, source, events etc... - without counts
   */
  search_meta_fast(value, iparams): Observable<any> {
    const paramsToBeSent = ['period', ...this.paramsForAllRequests];
    // let params = {};
    // if (iparams) { params = this.myParams(iparams, paramsToBeSent); }
    // params['size'] = '50';
    // params['q'] = value;
    const qparam = encodeURIComponent(value);
    const size = '50';
    const options = this.getOption(null);
    const requestUrlMeta = `${this.apiurlMetadata}/search?q=${qparam}&size=${size}`;
    const requestUrlSource = `${this.apiurl}/search/source?q=${qparam}&size=${size}`;
    return forkJoin([this.http.get(requestUrlMeta, options), this.http.get(requestUrlSource, options)]).pipe(map(resArg => {
      const res = JSON.parse(JSON.stringify(resArg[0]));
      const resSources = JSON.parse(JSON.stringify(resArg[1]));
      const entitiesWithCounts = [];
      // @ts-ignore
      res['entity_prefix_search']['hits'].forEach(item => {
        const entity = Object.assign({}, item, {
          count: null
        });
        if ((entity.entity.type === 'company') || (entity.entity.type === 'organization') || (entity.entity.type === 'commodity') || (entity.count && (entity.count > 0))) {
          entitiesWithCounts.push(entity);
        }
      });
      const urlSources = [];
      resSources['source_prefix_search']['hits'].forEach(item => {
        if (item.url) {
          urlSources.push(item);
        }
      });
      return Object.assign({}, res, {
        entity_prefix_search: {
          hits: entitiesWithCounts
        },
        source_prefix_search: {
          hits: urlSources
        }
      });
    }));
  }

  /**
   * search for PNEs...
   */
  search_pne(value, iparams): Observable<any> {
    const paramsToBeSent = ['period', ...this.paramsForAllRequests];
    let params = {};
    if (iparams) { params = this.myParams(iparams, paramsToBeSent); }
    params['size'] = '50';
    // params['q'] = value;
    const qparam = encodeURIComponent(value);
    const options = this.getOption(params);
    const requestUrl = `${this.apiurl}/search/potential_named_entity?q=${qparam}`;
    return this.http.get(requestUrl, options).pipe(mergeMap(resArg => {
      const res = JSON.parse(JSON.stringify(resArg));
      let portfolio = [];
      // @ts-ignore
      res.forEach(item => {
        portfolio = portfolio.concat(item.compound_keys);
      });
      return this.newsCountItemized(iparams, portfolio).pipe(map(resArg2 => {
        const res2 = JSON.parse(JSON.stringify(resArg2));
        const countsObject = {};
        res2.result.forEach(item => {
          countsObject[item.entity_info.compound_key] = item.count;
        });
        const pnesWithCounts = [];
        // @ts-ignore
        res.forEach(item => {
          const pneCounts = [];
          item.compound_keys.forEach(el => {
            pneCounts.push(countsObject[el]);
          });
          const pne = Object.assign({}, item, {
            counts: pneCounts
          });
          pnesWithCounts.push(pne);
        });
        return pnesWithCounts;
      }));
    }));
  }

  /**
   * news counts for a portfolio
   */
  newsCountItemized(iparams, portfolio): Observable<any> {
    const paramsToBeSent = ['period', ...this.paramsForAllRequests];
    let params = {};
    if (iparams) { params = this.myParams(iparams, paramsToBeSent); }
    // params['top_news_score_threshold'] = 0.0;
    const options = this.getOption(params);
    const requestUrl = `${this.apiurl}/api/portfolio/news_count_itemized`;
    return this.http.post(requestUrl, portfolio, options).pipe(map(res => {
      return res;
    }), catchError(error => {
      return of({result: portfolio.map(el => {
        return {
          count: null,
          entity_info: {
            compound_key: el,
            type: el.split(':')[0],
            alpha_id: el.split(':')[1]
          }
        };
        })});
    }));
  }

  /**
  * list of news for the newsfeed
  */
  newsfeed(obj): Observable<any> {
    obj.params = JSON.parse(JSON.stringify(obj.params));
    const paramsToBeSent = ['temporality', 'factuality', 'size', 'tag', 'participant', ...this.paramsForAllRequests];
    const params = this.myParams(obj.params, paramsToBeSent);
    if (obj.params.filter && obj.custom.size > 1) { params['sentiment'] = obj.params.filter; }
    // if (obj.custom.next && obj.custom.next !== 'null') { params['news_after'] = obj.custom.next; }
    if (obj.custom.from_index && obj.custom.from_index !== 0) { params['from_index'] = obj.custom.from_index; }
    if (obj.custom.size) { params['size'] = obj.custom.size; }
    if (obj.params.newsday) {
      params['from'] = moment(obj.params.newsday).format('YYYY-MM-DD');
      params['to'] = moment(obj.params.newsday).add(1, 'days').format('YYYY-MM-DD');
    }
    if (!obj.params.newsday && obj.params.scoreevent && params.hasOwnProperty('to')) {
      const originalFrom = moment(params['from']).format('YYYY-MM-DD');
      const ninetyDaysFrom = moment(params['to']).subtract(100, 'days').format('YYYY-MM-DD');
      if (originalFrom > ninetyDaysFrom) {
        params['from'] = ninetyDaysFrom;
      }
    }
    if (!obj.params.newsday && obj.params.flagevent && params.hasOwnProperty('to')) {
      const originalFrom = moment(params['from']).format('YYYY-MM-DD');
      const oneYearFrom = moment(params['to']).subtract(465, 'days').format('YYYY-MM-DD');
      if (originalFrom > oneYearFrom) {
        params['from'] = oneYearFrom;
      }
    }
    /*if (obj.params.scoreevent || obj.params.flagevent) {
      params['document_category_filter'] = 'business,business_pro_forma';
    }*/
    if (!obj.params.filter && obj.params.noise === 'true' && obj.custom.size > 1) {
      params['sentiment'] = 'f';
    }
    if (obj.params.duplicate === 'true') {
      params['collapse_similar_title'] = 'true';
    } else {
      if (!(obj.params.duplicate === 'false') && (!obj.params.newstype || (obj.params.newstype === 'Relevant') || (obj.params.newstype === 'Chronological') || (obj.params.newstype === 'Events') || (obj.params.newstype === 'Stories'))) {
        params['collapse_similar_title'] = 'true';
      }
    }
    if ((obj.params.license === 'true') || obj.custom.license) {
      params['feed_filter'] = 'is_licensed_content';
    }
    if ((obj.params.title === 'true') || obj.custom.title) {
      if (obj.params.tag) {
        const compound_key = obj.params.tag.split(':')[1] + ':' + obj.params.tag.split(':')[2];
        params['only_entities_in_title'] = compound_key;
      } else if (obj.params.type && obj.params.id) {
        params['only_entities_in_title'] = obj.params.type + ':' + obj.params.id;
      } else if (this.routing.isHomepage() && this.auth.folio && this.auth.folio.contentTop) {
        // const entities = this.auth.folio.contentTop.map(item => {
        //   return item.split(':')[1];
        // }).join();
        params['only_entities_in_title'] = 'all';
      } else if (this.routing.isFolio() && this.auth.folio && this.auth.folio.content) {
        // const entities = this.auth.folio.content.map(item => {
        //   return item.split(':')[1];
        // }).join();
        params['only_entities_in_title'] = 'all';
      }
    }
    // if (obj.custom.starred_only) {
    //   params['starred_only'] = true;
    // }
    // if (obj.custom.audited_only) {
    //   params['audited_only'] = true;
    // }
    // if (obj.custom.dismissed_only) {
    //   params['dismissed_only'] = true;
    // }
    if (obj.custom.top_news_score_threshold) {
      params['top_news_score_threshold'] = '0.45';
    } else {
      // params['top_news_score_threshold'] = '0.0';
    }
    if (obj.params.event_ids) {
      params['event_ids'] = obj.params.event_ids;
    }
    if (obj.params.type === 'event') {
      if (obj.params.id === 'super_event' || obj.params.id === 'event' || obj.params.id === 'sub_event') {
        obj.params.type = 'all';
        obj.params.id = 'all';
        if (obj.params.eventype && ( obj.params.eventype !== 'super_event' ) && ( obj.params.eventype !== 'event' ) && ( obj.params.eventype !== 'sub_event' ) ) {
          if ( obj.params.treemap && ( obj.params.treemap.split(',')[3] === obj.params.eventid ) ) {
            params['event_ids'] = obj.params.treemap.split(',')[2];
          }
          obj.params.type = obj.params.eventype;
          obj.params.id = obj.params.eventid;
        } else if ( !obj.params.eventype ) {
          params['has_events'] = 'true';
        }
      } else if (obj.params.id) {
        params['event_ids'] = obj.params.id;
      }
    }
    if (obj.params.type === 'super_event' || obj.params.type === 'sub_event') {
      params['event_ids'] = obj.params.id;
    }
    if (obj.params.eventid && (obj.params.eventype === 'super_event' || obj.params.eventype === 'event' || obj.params.eventype === 'sub_event')) {
      params['event_ids'] = obj.params.eventid;
      // if (!obj.custom.starred_only) {
      //   params['force_event_based_news_ids'] = 'true';
      //   params['document_category_filter'] = 'politics,patents,business,technical_analysis,weather,analyst_rating,tender_procurement,macroecon,business_pro_forma,tech,sport,medical_trials,entertainment,police_reports';
      // }
    }
    if (obj.params.newstype && (obj.params.newstype === 'Events')) {
      params['full_news'] = true;
    }
    if (obj.returnStories) {
      params['min_size'] = 4;
      if (obj.params.top_news_score_threshold) {
        params['top_news_score_threshold'] = obj.params.top_news_score_threshold;
      } else {
        params['top_news_score_threshold'] = '0.45';
      }
      if (obj.params.collapse_similar_title) {
        params['collapse_similar_title'] = obj.params.collapse_similar_title;
      } else {
        params['collapse_similar_title'] = 'false';
      }
      if (obj.params.algo) {
        params['algo'] = obj.params.algo;
      } else {
        params['algo'] = 'agglom';
      }
      if (obj.params.affinity) {
        params['affinity'] = obj.params.affinity;
      } else {
        params['affinity'] = 'cosine';
      }
      if (obj.params.linkage) {
        params['linkage'] = obj.params.linkage;
      } else {
        params['linkage'] = 'average';
      }
      if (obj.params.distance_threshold) {
        params['distance_threshold'] = obj.params.distance_threshold;
      } else {
        params['distance_threshold'] = '0.1';
      }
      return this.iRequest(obj.params, params, 'stories').pipe(map(respArg => {
        const resp = JSON.parse(JSON.stringify(respArg));
        if (resp.hasOwnProperty('stories')) {
          resp['stories'].forEach((story, indexStory) => {
            if (story.hasOwnProperty('main_article')) {
              story['main_article']['events'].forEach((event, indexEvent) => {
                if (event.span && story['main_article'].locations) {
                  if (event.span.end <= story['main_article'].locations.title.end) {
                    resp['stories'][indexStory]['main_article']['events'][indexEvent].where = 'title';
                    resp['stories'][indexStory]['main_article']['events'][indexEvent].span.start -= story['main_article'].locations.title.start;
                    resp['stories'][indexStory]['main_article']['events'][indexEvent].span.end -= story['main_article'].locations.title.start;
                  } else {
                    resp['stories'][indexStory]['main_article']['events'][indexEvent].where = 'content';
                    resp['stories'][indexStory]['main_article']['events'][indexEvent].span.start -= story['main_article'].locations.content.start;
                    resp['stories'][indexStory]['main_article']['events'][indexEvent].span.end -= story['main_article'].locations.content.start;
                  }
                }
              });
              story['main_article']['matches'].forEach((match, indexMatch) => {
                match['spans'].forEach((span, indexSpan) => {
                  if (span && story['main_article'].locations) {
                    if (span.end <= story['main_article'].locations.title.end) {
                      resp['stories'][indexStory]['main_article']['matches'][indexMatch]['spans'][indexSpan].location = 'title';
                      resp['stories'][indexStory]['main_article']['matches'][indexMatch]['spans'][indexSpan].start -= story['main_article'].locations.title.start;
                      resp['stories'][indexStory]['main_article']['matches'][indexMatch]['spans'][indexSpan].end -= story['main_article'].locations.title.start;
                    } else {
                      resp['stories'][indexStory]['main_article']['matches'][indexMatch]['spans'][indexSpan].location = 'content';
                      resp['stories'][indexStory]['main_article']['matches'][indexMatch]['spans'][indexSpan].start -= story['main_article'].locations.content.start;
                      resp['stories'][indexStory]['main_article']['matches'][indexMatch]['spans'][indexSpan].end -= story['main_article'].locations.content.start;
                    }
                  }
                });
              });
            }
          });
          return resp;
        } else {
          return resp;
        }
      }));
    } else if (obj.returnEvents) {
      params['deduplicate'] = true;
      return this.iRequest(obj.params, params, 'event_snippets').pipe(map(respArg => {
        const resp = JSON.parse(JSON.stringify(respArg));
        if (resp.hasOwnProperty('event_snippets')) {
          return {
            news: resp['event_snippets'].map(item => {
              let type, alpha_id, compound_key;
              if (obj.params.tag) {
                compound_key = obj.params.tag.split(':')[1] + ':' + obj.params.tag.split(':')[2];
                type = obj.params.tag.split(':')[1];
                alpha_id = obj.params.tag.split(':')[2];
              } else if (obj.params.type && obj.params.id) {
                compound_key = obj.params.type + ':' + obj.params.id;
                type = obj.params.type;
                alpha_id = obj.params.id;
              }
              return {
                reduce_visibility: false,
                // duplicate_group_id: null,
                document_categorization: item.document_categorization,
                has_events: [true],
                title: item.snippet,
                id: item.article_id,
                provider: item.provider,
                publish_time: item.article_publish_time,
                sentiment_proportions: item.sentiment_proportions,
                locations: {
                  title: {
                    start: item.event_span.start,
                    end: item.event_span.end
                  },
                  content: {
                    start: item.event_span.start,
                    end: item.event_span.start,
                  }
                },
                events: item.events.filter(el => (el.id === obj.params.eventid)).map(el => {
                  return Object.assign({}, el, {
                    span: {
                      start: item.event_span.start,
                      end: item.event_span.end
                    },
                    where: 'title'
                  });
                }),
                matches: [{
                  implied: false,
                  entity: {
                    alpha_id: alpha_id,
                    type: type,
                    compound_key: compound_key
                  },
                  spans: item.event_participant_spans.map(el => {
                    return {
                      location: 'title',
                      implied: false,
                      is_coref: false,
                      start: el.start,
                      end: el.end
                    };
                  })
                }]
              };
            })
          };
        } else {
          return resp;
        }
      }));
    } else if (obj.returnRecommendations) {
      if (obj.custom.phrase) {
        params['title'] = obj.custom.phrase;
      }
      // if (obj.custom.size) {
      //   params['size'] = obj.custom.size;
      // }
      if (obj.custom.similarity_threshold) {
        params['similarity_threshold'] = obj.custom.similarity_threshold;
      }
      return this.iRequest(obj.params, params, 'semantic_search').pipe(map(respArg => {
        const resp = JSON.parse(JSON.stringify(respArg));
        if (resp.hasOwnProperty('news')) {
          resp['news'].forEach((news, indexNews) => {
            news['events'].forEach((event, indexEvent) => {
              if (event.span && news.locations) {
                if (event.span.end <= news.locations.title.end) {
                  resp['news'][indexNews]['events'][indexEvent].where = 'title';
                  resp['news'][indexNews]['events'][indexEvent].span.start -= news.locations.title.start;
                  resp['news'][indexNews]['events'][indexEvent].span.end -= news.locations.title.start;
                } else {
                  resp['news'][indexNews]['events'][indexEvent].where = 'content';
                  resp['news'][indexNews]['events'][indexEvent].span.start -= news.locations.content.start;
                  resp['news'][indexNews]['events'][indexEvent].span.end -= news.locations.content.start;
                }
              }
            });
            news['matches'].forEach((match, indexMatch) => {
              match['spans'].forEach((span, indexSpan) => {
                if (span && news.locations) {
                  if (span.end <= news.locations.title.end) {
                    resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].location = 'title';
                    resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].start -= news.locations.title.start;
                    resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].end -= news.locations.title.start;
                  } else {
                    resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].location = 'content';
                    resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].start -= news.locations.content.start;
                    resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].end -= news.locations.content.start;
                  }
                }
              });
            });
          });
          return resp;
        } else {
          return resp;
        }
      }));
    } else {
      let requestCustomerAPI = of(null);
      if (obj.custom.starred_only) {
        requestCustomerAPI = this.auth.getStars();
      }
      if (obj.custom.audited_only) {
        requestCustomerAPI = this.auth.getAuditedIds();
      }
      if (obj.custom.dismissed_only) {
        requestCustomerAPI = this.auth.getDismissedIds();
      }
      if (obj.custom.exclude_dismissed) {
        const newsletterId = (obj.custom.newsletter_id) ? obj.custom.newsletter_id : null;
        requestCustomerAPI = this.auth.getNewsletterDismissed(newsletterId);
      }
      return requestCustomerAPI.pipe(mergeMap(resArg => {
        const res = JSON.parse(JSON.stringify(resArg));
        let resIds = [];
        if (obj.custom.audited_only || obj.custom.dismissed_only) {
          for (const prop of Object.keys(res)) {
            resIds = resIds.concat(res[prop]);
          }
        } else if (obj.custom.starred_only) {
          resIds = res;
        } else if (obj.custom.exclude_dismissed) {
          resIds = res.news_ids;
        } else {
          resIds = null;
        }
        if (resIds && !obj.custom.exclude_dismissed) {
          params['headerIncludeNewsIds'] = resIds.join();
        } else if (resIds && obj.custom.exclude_dismissed) {
          params['headerExcludeNewsIds'] = res.news_ids;
        }
        return this.iRequest(obj.params, params, 'news').pipe(mergeMap(respArg => {
          const resp = JSON.parse(JSON.stringify(respArg));
          const uniqueKeys = new Set();
          respArg['news'].forEach(news => {
            news['events'].forEach(event => {
              event['participants'].forEach(participant => {
                uniqueKeys.add(participant.compound_key);
              });
            });
          });
          if (resp.hasOwnProperty('news')) {
            return this.getEntitiesInfo([...uniqueKeys]).pipe(map(result => {
              resp['news'].forEach((news, indexNews) => {
                news['events'].forEach((event, indexEvent) => {
                  if (event.hasOwnProperty('participants')) {
                    event['participants'].forEach((participant, indexParticipant) => {
                      resp['news'][indexNews]['events'][indexEvent]['participants'][indexParticipant] = result[participant.compound_key];
                    });
                  }
                  if (event.span && news.locations) {
                    if (event.span.end <= news.locations.title.end) {
                      resp['news'][indexNews]['events'][indexEvent].where = 'title';
                      resp['news'][indexNews]['events'][indexEvent].span.start -= news.locations.title.start;
                      resp['news'][indexNews]['events'][indexEvent].span.end -= news.locations.title.start;
                    } else {
                      resp['news'][indexNews]['events'][indexEvent].where = 'content';
                      resp['news'][indexNews]['events'][indexEvent].span.start -= news.locations.content.start;
                      resp['news'][indexNews]['events'][indexEvent].span.end -= news.locations.content.start;
                    }
                  }
                });
                news['matches'].forEach((match, indexMatch) => {
                  match['spans'].forEach((span, indexSpan) => {
                    if (span && news.locations) {
                      if (span.end <= news.locations.title.end) {
                        resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].location = 'title';
                        resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].start -= news.locations.title.start;
                        resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].end -= news.locations.title.start;
                      } else {
                        resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].location = 'content';
                        resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].start -= news.locations.content.start;
                        resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].end -= news.locations.content.start;
                      }
                    }
                  });
                });
              });
              return resp;
            }), catchError(error => {
              resp['news'].forEach((news, indexNews) => {
                news['events'].forEach((event, indexEvent) => {
                  if (event.span && news.locations) {
                    if (event.span.end <= news.locations.title.end) {
                      resp['news'][indexNews]['events'][indexEvent].where = 'title';
                      resp['news'][indexNews]['events'][indexEvent].span.start -= news.locations.title.start;
                      resp['news'][indexNews]['events'][indexEvent].span.end -= news.locations.title.start;
                    } else {
                      resp['news'][indexNews]['events'][indexEvent].where = 'content';
                      resp['news'][indexNews]['events'][indexEvent].span.start -= news.locations.content.start;
                      resp['news'][indexNews]['events'][indexEvent].span.end -= news.locations.content.start;
                    }
                  }
                });
                news['matches'].forEach((match, indexMatch) => {
                  match['spans'].forEach((span, indexSpan) => {
                    if (span && news.locations) {
                      if (span.end <= news.locations.title.end) {
                        resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].location = 'title';
                        resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].start -= news.locations.title.start;
                        resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].end -= news.locations.title.start;
                      } else {
                        resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].location = 'content';
                        resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].start -= news.locations.content.start;
                        resp['news'][indexNews]['matches'][indexMatch]['spans'][indexSpan].end -= news.locations.content.start;
                      }
                    }
                  });
                });
              });
              return of(resp);
            }));
          } else {
            return of(resp);
          }
        }));
      }));
    }
  }

  /**
   * data from specific news
   */
  newsviewNew(obj): Observable<any> {
    obj.params = JSON.parse(JSON.stringify(obj.params));
    // const paramsToBeSent = ['event_ids', 'participant', 'tag'];
    // const paramsToBeSent = ['event_ids', 'participant', 'tag', ...this.paramsForAllRequests];
    const paramsToBeSent = ['event_ids', 'participant', 'tag', 'from', 'to', 'lang'];
    let params = {};
    params = this.myParams(obj.params, paramsToBeSent);

    // adding timeframe should make endpoint faster
    if (obj.params.newsday) {
      params['from'] = moment(obj.params.newsday).format('YYYY-MM-DD');
      params['to'] = moment(obj.params.newsday).add(1, 'days').format('YYYY-MM-DD');
    }
    if (!obj.params.newsday && obj.params.scoreevent && params.hasOwnProperty('to')) {
      const originalFrom = moment(params['from']).format('YYYY-MM-DD');
      const ninetyDaysFrom = moment(params['to']).subtract(100, 'days').format('YYYY-MM-DD');
      if (originalFrom > ninetyDaysFrom) {
        params['from'] = ninetyDaysFrom;
      }
    }
    if (!obj.params.newsday && obj.params.flagevent && params.hasOwnProperty('to')) {
      const originalFrom = moment(params['from']).format('YYYY-MM-DD');
      const oneYearFrom = moment(params['to']).subtract(465, 'days').format('YYYY-MM-DD');
      if (originalFrom > oneYearFrom) {
        params['from'] = oneYearFrom;
      }
    }

    // if (this.routing.isDev()) {
    //   params['show_sentilyzer_metadata'] = 'true';
    // }
    if ((this.routing.isReader() || this.routing.isStoryReader()) && obj.params.jwt) {
      params['jwt'] = obj.params.jwt;
    }
    if (obj.params.type === 'event') {
      if (obj.params.id === 'super_event' || obj.params.id === 'event' || obj.params.id === 'sub_event') {
        obj.params.type = 'all';
        obj.params.id = 'all';
        if (obj.params.eventype && ( obj.params.eventype !== 'super_event' ) && ( obj.params.eventype !== 'event' ) && ( obj.params.eventype !== 'sub_event' ) ) {
          if ( obj.params.treemap && ( obj.params.treemap.split(',')[3] === obj.params.eventid ) ) {
            params['event_ids'] = obj.params.treemap.split(',')[2];
          }
          obj.params.type = obj.params.eventype;
          obj.params.id = obj.params.eventid;
        } else if ( !obj.params.eventype ) {
          params['has_events'] = 'true';
        }
      } else if (obj.params.id) {
        params['event_ids'] = obj.params.id;
      }
    }
    if (obj.params.type === 'super_event' || obj.params.type === 'sub_event') {
      params['event_ids'] = obj.params.id;
    }
    if (obj.params.eventid && (obj.params.eventype === 'super_event' || obj.params.eventype === 'event' || obj.params.eventype === 'sub_event')) {
      params['event_ids'] = obj.params.eventid;
    }
    return this.iRequest(Object.assign(obj.params, {newsId: obj.id}), params, 'newsview').pipe(mergeMap(respArg => {
      const resp = JSON.parse(JSON.stringify(respArg));
      const uniqueKeys = new Set();
      respArg['matches'].forEach(match => {
        uniqueKeys.add(match.entity.compound_key);
      });
      respArg['events'].forEach(event => {
        event['participants'].forEach(participant => {
          uniqueKeys.add(participant.compound_key);
        });
      });
      respArg['tags'].forEach(tag => {
        if (tag.tag.split(':')[0] === 'm') {
          const compoundKey = tag.tag.split(':')[1] + ':' + tag.tag.split(':')[2];
          uniqueKeys.add(compoundKey);
        }
      });
      return this.getEntitiesInfo([...uniqueKeys]).pipe(map(result => {
        if (resp.hasOwnProperty('title') && resp.hasOwnProperty('content')) {
          resp['events'].forEach((event, indexEvent) => {
            if (event.hasOwnProperty('participants')) {
              event['participants'].forEach((participant, indexParticipant) => {
                resp['events'][indexEvent]['participants'][indexParticipant] = result[participant.compound_key];
              });
            }
            if (event.span && resp['locations']) {
              if (event.span.end <= resp['locations'].title.end) {
                resp['events'][indexEvent].where = 'title';
                resp['events'][indexEvent].span.start -= resp['locations'].title.start;
                resp['events'][indexEvent].span.end -= resp['locations'].title.start;
              } else {
                resp['events'][indexEvent].where = 'content';
                resp['events'][indexEvent].span.start -= resp['locations'].content.start;
                resp['events'][indexEvent].span.end -= resp['locations'].content.start;
              }
            }
          });
          resp['matches'].forEach((match, indexMatch) => {
            match['entity'] = result[match['entity']['compound_key']];
            match['spans'].forEach((span, indexSpan) => {
              if (span && resp['locations']) {
                if (span.end <= resp['locations'].title.end) {
                  resp['matches'][indexMatch]['spans'][indexSpan].location = 'title';
                  resp['matches'][indexMatch]['spans'][indexSpan].start -= resp['locations'].title.start;
                  resp['matches'][indexMatch]['spans'][indexSpan].end -= resp['locations'].title.start;
                } else {
                  resp['matches'][indexMatch]['spans'][indexSpan].location = 'content';
                  resp['matches'][indexMatch]['spans'][indexSpan].start -= resp['locations'].content.start;
                  resp['matches'][indexMatch]['spans'][indexSpan].end -= resp['locations'].content.start;
                }
              }
            });
          });
          resp['matches'] = resp['matches'].filter(match => !['source_name', 'source_uri'].includes(match.entity.type));
          // resp['matches'] = resp['matches'].filter(match => !match.implied);
          resp['tags'].forEach((tag, indexTag) => {
            if (tag.tag.split(':')[0] === 'm') {
              tag['word'] = result[tag.tag.split(':')[1] + ':' + tag.tag.split(':')[2]].name;
            }
            tag['spans'].forEach((span, indexSpan) => {
              if (span && resp['locations']) {
                if (span.end <= resp['locations'].title.end) {
                  resp['tags'][indexTag]['spans'][indexSpan].location = 'title';
                  resp['tags'][indexTag]['spans'][indexSpan].start -= resp['locations'].title.start;
                  resp['tags'][indexTag]['spans'][indexSpan].end -= resp['locations'].title.start;
                } else {
                  resp['tags'][indexTag]['spans'][indexSpan].location = 'content';
                  resp['tags'][indexTag]['spans'][indexSpan].start -= resp['locations'].content.start;
                  resp['tags'][indexTag]['spans'][indexSpan].end -= resp['locations'].content.start;
                }
              }
            });
          });
          resp['query_highlights'].forEach((highlight, indexHighlight) => {
            if (highlight.span && resp['locations']) {
              if (highlight.span.end <= resp['locations'].title.end) {
                resp['query_highlights'][indexHighlight].where = 'title';
                resp['query_highlights'][indexHighlight].span.start -= resp['locations'].title.start;
                resp['query_highlights'][indexHighlight].span.end -= resp['locations'].title.start;
              } else {
                resp['query_highlights'][indexHighlight].where = 'content';
                resp['query_highlights'][indexHighlight].span.start -= resp['locations'].content.start;
                resp['query_highlights'][indexHighlight].span.end -= resp['locations'].content.start;
              }
            }
          });
          return resp;
        } else {
          return resp;
        }
      }), catchError(error => {
        if (resp.hasOwnProperty('title') && resp.hasOwnProperty('content')) {
          resp['events'] = [];
          resp['matches'] = [];
          resp['tags'].forEach((tag, indexTag) => {
            tag['spans'].forEach((span, indexSpan) => {
              if (span && resp['locations']) {
                if (span.end <= resp['locations'].title.end) {
                  resp['tags'][indexTag]['spans'][indexSpan].location = 'title';
                  resp['tags'][indexTag]['spans'][indexSpan].start -= resp['locations'].title.start;
                  resp['tags'][indexTag]['spans'][indexSpan].end -= resp['locations'].title.start;
                } else {
                  resp['tags'][indexTag]['spans'][indexSpan].location = 'content';
                  resp['tags'][indexTag]['spans'][indexSpan].start -= resp['locations'].content.start;
                  resp['tags'][indexTag]['spans'][indexSpan].end -= resp['locations'].content.start;
                }
              }
            });
          });
          resp['query_highlights'] = [];
          return of(resp);
        } else {
          return of(resp);
        }
      }));
    }));
  }

  /**
  * data from specific news
  */
  newsview(obj): Observable<any> {
    const paramsToBeSent = [];
    let params = {};
    params = this.myParams(obj.params, paramsToBeSent);
    // if (this.routing.isDev()) {
    //   params['show_sentilyzer_metadata'] = 'true';
    // }
    if ((this.routing.isReader() || this.routing.isStoryReader()) && obj.params.jwt) {
      params['jwt'] = obj.params.jwt;
    }
    const options = this.getOption(params);
    const requestUrl = `${this.apiurl}/news/id/${obj.id}`;
    return this.http.get(requestUrl, options);
  }

  /**
   * articles for a particular story
   */
  newsfeedStory(obj): Observable<any> {
    const params = {};
    if ((this.routing.isReader() || this.routing.isStoryReader()) && obj.params && obj.params.jwt) {
      params['jwt'] = obj.params.jwt;
    }
    const options = this.getOption(params);
    const requestUrl = `${this.apiurl}/api/stories/${obj.storyId}`;
    return this.http.get(requestUrl, options).pipe(map(respArg => {
      const resp = JSON.parse(JSON.stringify(respArg));
      if (resp.hasOwnProperty('articles')) {
        resp['articles'].forEach((news, indexNews) => {
          news['events'].forEach((event, indexEvent) => {
            if (event.span && news.locations) {
              if (event.span.end <= news.locations.title.end) {
                resp['articles'][indexNews]['events'][indexEvent].where = 'title';
                resp['articles'][indexNews]['events'][indexEvent].span.start -= news.locations.title.start;
                resp['articles'][indexNews]['events'][indexEvent].span.end -= news.locations.title.start;
              } else {
                resp['articles'][indexNews]['events'][indexEvent].where = 'content';
                resp['articles'][indexNews]['events'][indexEvent].span.start -= news.locations.content.start;
                resp['articles'][indexNews]['events'][indexEvent].span.end -= news.locations.content.start;
              }
            }
          });
          news['matches'].forEach((match, indexMatch) => {
            match['spans'].forEach((span, indexSpan) => {
              if (span && news.locations) {
                if (span.end <= news.locations.title.end) {
                  resp['articles'][indexNews]['matches'][indexMatch]['spans'][indexSpan].location = 'title';
                  resp['articles'][indexNews]['matches'][indexMatch]['spans'][indexSpan].start -= news.locations.title.start;
                  resp['articles'][indexNews]['matches'][indexMatch]['spans'][indexSpan].end -= news.locations.title.start;
                } else {
                  resp['articles'][indexNews]['matches'][indexMatch]['spans'][indexSpan].location = 'content';
                  resp['articles'][indexNews]['matches'][indexMatch]['spans'][indexSpan].start -= news.locations.content.start;
                  resp['articles'][indexNews]['matches'][indexMatch]['spans'][indexSpan].end -= news.locations.content.start;
                }
              }
            });
          });
        });
        if (resp.hasOwnProperty('main_article')) {
          resp['main_article']['events'].forEach((event, indexEvent) => {
            if (event.span && resp['main_article'].locations) {
              if (event.span.end <= resp['main_article'].locations.title.end) {
                resp['main_article']['events'][indexEvent].where = 'title';
                resp['main_article']['events'][indexEvent].span.start -= resp['main_article'].locations.title.start;
                resp['main_article']['events'][indexEvent].span.end -= resp['main_article'].locations.title.start;
              } else {
                resp['main_article']['events'][indexEvent].where = 'content';
                resp['main_article']['events'][indexEvent].span.start -= resp['main_article'].locations.content.start;
                resp['main_article']['events'][indexEvent].span.end -= resp['main_article'].locations.content.start;
              }
            }
          });
          resp['main_article']['matches'].forEach((match, indexMatch) => {
            match['spans'].forEach((span, indexSpan) => {
              if (span && resp['main_article'].locations) {
                if (span.end <= resp['main_article'].locations.title.end) {
                  resp['main_article']['matches'][indexMatch]['spans'][indexSpan].location = 'title';
                  resp['main_article']['matches'][indexMatch]['spans'][indexSpan].start -= resp['main_article'].locations.title.start;
                  resp['main_article']['matches'][indexMatch]['spans'][indexSpan].end -= resp['main_article'].locations.title.start;
                } else {
                  resp['main_article']['matches'][indexMatch]['spans'][indexSpan].location = 'content';
                  resp['main_article']['matches'][indexMatch]['spans'][indexSpan].start -= resp['main_article'].locations.content.start;
                  resp['main_article']['matches'][indexMatch]['spans'][indexSpan].end -= resp['main_article'].locations.content.start;
                }
              }
            });
          });
        }
        return resp;
      } else {
        return resp;
      }
    }));
  }

  /**
  * list of top 100 entity trending
  */
  trending(iparams, entity): Observable<any> {
    const paramsToBeSent = ['period', ...this.paramsForAllRequests];
    const params = this.myParams(iparams, paramsToBeSent);
    params['entity_types'] = entity;
    params['size'] = 500;
    if (iparams.how) { params['how'] = iparams.how; }
    const options = this.getOption(params);
    // if (iparams.query) {
    //   const requestUrl = `${this.apiurl}/api/search_query/multi_trend_radar`
    //   return this.http.post(requestUrl, iparams.query, options)
    // } else {
    const requestUrl = `${this.apiurl}/api/trending`;
    return this.http.get(requestUrl, options);
    // }
  }

  /**
   * list of top bull/bear trending companies
   */
  bbtrending(iparams, type): Observable<any> {
    const params = {};
    if (iparams.amount) { params['amount'] = iparams.amount; }
    if (iparams.stock_index) { params['stock_index'] = iparams.stock_index; }
    if (iparams.industry) { params['industry'] = iparams.industry; }
    if (iparams.time_horizon) { params['time_horizon'] = iparams.time_horizon; }
    if (iparams.country) { params['source_country'] = iparams.country; }
    const options = this.getOption(params);
    const requestUrl = `${this.apiurlQuant}/signal/` + type;
    return this.http.get(requestUrl, options);
  }

  /**
   * all endpoints filters' lists with names and values
   */
  getFiltersList(): Observable<any> {
    const requestUrl = this.apiurl + '/filter_list';
    const options = this.getOption(null);
    return this.http.get(requestUrl, options).pipe(
      mergeMap(resArg => {
        const res = JSON.parse(JSON.stringify(resArg));
        return forkJoin({continents: this.getContinents(), countries: this.getCountries(), events: this.getHierarchyInfo('event')}).pipe(map(resArg2 => {
          const res2 = JSON.parse(JSON.stringify(resArg2));
          // res2.continents.entities.push({alpha_id: 'middle_east', name: 'Middle East', type: 'continent'});
          const filtersArray = [];
          res.forEach(el => {
            if (!['source_continents', 'source_countries', 'events'].includes(el.filter)) {
              filtersArray.push(el);
            }
          });
          filtersArray.push({
            filter: 'source_continents',
            values: res2.continents.entities.map(el => {
              return {
                value: el.alpha_id,
                name: el.name
              };
            })
          });
          filtersArray.push({
            filter: 'source_countries',
            values: res2.countries.entities.map(el => {
              return {
                value: el.alpha_id,
                name: el.name
              };
            })
          });
          filtersArray.push({
            filter: 'events',
            values: res2.events.event_structure
          });
          return filtersArray;
        }));
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.error('An error occurred:', err.error.message);
        } else {
          console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
        }
        return of([]);
      })
    );
  }

  /**
  * treemap data for the market board and the company page
  */
  treemap(iparams): Observable<any> {
    if (iparams && iparams.type && (iparams.type === 'hierarchy')) {
      if (iparams.id && ['industry', 'country', 'index'].includes(iparams.id)) {

        return this.getHierarchyInfo(iparams.id);

      } else {
        return this.getHierarchyInfo('icb').pipe(mergeMap(resArg => {
          const res = JSON.parse(JSON.stringify(resArg));
          const requestSentimentList = [];
          const industries = [];
          res.treemap.children.forEach((industry, index) => {
            industries.push(industry.entity.compound_key);
            const supersectors = [];
            industry.children.forEach(supersector => {
              supersectors.push(supersector.entity.compound_key);
            });
            requestSentimentList.push(this.sentimentItemized(iparams, supersectors));
          });
          requestSentimentList.unshift(this.sentimentItemized(iparams, industries));
          return forkJoin(requestSentimentList).pipe(map(resArg2 => {
            const res2 = JSON.parse(JSON.stringify(resArg2));
            const responseObject = Object.assign({}, res);
            responseObject.treemap.children.forEach((industry, index) => {
              responseObject.treemap.children[index] = Object.assign({}, responseObject.treemap.children[index], res2[0][industry.entity.compound_key]);
              responseObject.treemap.children[index].children.forEach((supersector, index2) => {
                responseObject.treemap.children[index].children[index2] = Object.assign({}, responseObject.treemap.children[index].children[index2], res2[1 + index][supersector.entity.compound_key]);
              });
            });

            console.log(responseObject);

            return responseObject;
          }));
        }));
      }
    } else if (iparams && iparams.type && (iparams.type === 'company')) {
      return this.relations(iparams, ['country', 'industry', 'person', 'sector', 'subsector', 'supersector', 'stock_index']).pipe(map(resArg => {
        const res = JSON.parse(JSON.stringify(resArg));
        return {
          treemap: Object.assign({}, res, {
            children: Object.values(res.relations)
          })
        };
      }));
    } else if (iparams && iparams.type) {
      return this.relations(iparams, ['company']).pipe(map(resArg => {
        const res = JSON.parse(JSON.stringify(resArg));
        return {
          treemap: Object.assign({}, res, {
            children: res.relations.company.children
          })
        };
      }));
    } else {
      return of({
        treemap: {
          children: []
        }
      });
    }
  }

  /**
   * new treemap request - data for the market board and the company page
   */
  // treemapNew(iparams): Observable<any> {
  //   return this.getRelatedEntitiesInfo(iparams.type, iparams.id, 'company').pipe(mergeMap(res => {
  //     const content = [];
  //     // @ts-ignore
  //     res.forEach(item => {
  //       content.push(item.compound_key);
  //     });
  //     return this.sentimentItemized(iparams, content).pipe(map(res2 => {
  //       const treemapObject = {
  //         treemap: {
  //           children: []
  //         }
  //       };
  //       res.forEach(item => {
  //         const entityData = Object.assign({}, {
  //           entity: item
  //         }, res2[item.compound_key]);
  //         treemapObject.treemap.children.push(entityData);
  //       });
  //       return treemapObject;
  //     }));
  //   }));
  // }

  /**
   * treemap data for all events
   */
  getEventsTreemap(): Observable<any> {
    const time = '90';
    const fromto = this.myFromTo(time);
    const params = {
      from: fromto.date1,
      to: fromto.date2
    };
    const options = this.getOption(params);
    // const options = this.getOption(null);
    return this.http.get(this.apiurl + '/api/all/all/event_treemap', options);
  }

  /**
  * treemap data for specific event
  */
  evenTreemap(type, id, iparams): Observable<any> {
    const paramsToBeSent = [...this.paramsForAllRequests];
    const params = this.myParams(iparams, paramsToBeSent);
    const options = this.getOption(params);
    return this.http.get(this.apiurl + '/api/' + type + '/' + id + '/event_treemap', options);
  }

  /**
  * entities of specific portfolio
  */
  portfolio(iparams, folio): Observable<any> {
    return this.getEntitiesInfo(folio, 'array', (iparams.getSanctions ? true : false)).pipe(mergeMap(resArg => {
      const res = JSON.parse(JSON.stringify(resArg));
      const content = [];
      // @ts-ignore
      res.forEach(item => {
        content.push(item.compound_key);
      });
      return this.sentimentItemized(iparams, content).pipe(map(resArg2 => {
        const res2 = JSON.parse(JSON.stringify(resArg2));
        const treemapObject = {
          treemap: {
            children: []
          }
        };
        res.forEach(item => {
          const entityData = Object.assign({}, {
            entity: item
          }, res2[item.compound_key]);
          treemapObject.treemap.children.push(entityData);
        });
        return treemapObject;
      }));
    }));
  }

  /**
  * list of tag words
  */
  tagcloud(iparams): Observable<any> {
    iparams = JSON.parse(JSON.stringify(iparams));
    const paramsToBeSent = ['size', ...this.paramsForAllRequests];
    let params = {};
    params = this.myParams(iparams, paramsToBeSent);
    if (iparams.newsday) {
      params['from'] = moment(iparams.newsday).format('YYYY-MM-DD');
      params['to'] = moment(iparams.newsday).add(1, 'days').format('YYYY-MM-DD');
    }
    if (iparams.type === 'event' && iparams.id === 'super_event') {
      iparams.type = 'hierarchy';
      iparams.id = 'icb';
    }
    return this.iRequest(iparams, params, 'tagcloud').pipe(mergeMap(respArg => {
      const resp = JSON.parse(JSON.stringify(respArg));
      const uniqueKeys = new Set();
      respArg['tagcloud'].forEach(tag => {
        if (tag.tag.split(':')[0] === 'm') {
          const compoundKey = tag.tag.split(':')[1] + ':' + tag.tag.split(':')[2];
          uniqueKeys.add(compoundKey);
        }
      });
      return this.getEntitiesInfo([...uniqueKeys]).pipe(map(result => {
        resp['tagcloud'].forEach(tag => {
          if (tag.tag.split(':')[0] === 'm') {
            tag['word'] = result[tag.tag.split(':')[1] + ':' + tag.tag.split(':')[2]].name;
          }
        });
        return resp;
      }));
    }));
  }

  /**
   * list of related entities
   */
  relatedEntities(iparams, type, size): Observable<any> {
    iparams = JSON.parse(JSON.stringify(iparams));
    const returnParticipants = ( ( type && type === 'participants' ) && (this.routing.isQuery() || this.routing.isSearch() || iparams.eventype || (iparams.type === 'event') || (iparams.type === 'sub_event') || (iparams.type === 'super_event') ) );
    let paramsToBeSent = [];
    if ( returnParticipants ) {
      paramsToBeSent = ['size', 'entity_types', ...this.paramsForAllRequests];
    } else {
      paramsToBeSent = ['size', 'entity_types', 'event_ids', ...this.paramsForAllRequests];
    }
    let params = {};
    params = this.myParams(iparams, paramsToBeSent);
    if (iparams.newsday) {
      params['from'] = moment(iparams.newsday).format('YYYY-MM-DD');
      params['to'] = moment(iparams.newsday).add(1, 'days').format('YYYY-MM-DD');
    }
    // if ( !returnParticipants ) {
    //   params['top_entities_only'] = 'true';
    // }
    if ( !( type && type === 'participants' ) ) {
      params['top_entities_only'] = 'true';
    }
    if (type) {
      if (type === 'participants') {
        params['entity_types'] = 'company';
        if ( !returnParticipants ) {
          params['how'] = 'event_participants';
        }
      } else {
        params['entity_types'] = type;
      }
    }
    if (size) {
      params['size'] = size;
    }
    if ( returnParticipants ) {
      return this.iRequest(iparams, params, 'related_events_participants').pipe(mergeMap(resArg => {
        if (resArg.hasOwnProperty('related_events_participants')) {
          const respCopy = JSON.parse(JSON.stringify(resArg['related_events_participants']));
          const content = respCopy.map(el => el.entity.compound_key);
          return this.portfolio(iparams, content).pipe(map(resArg2 => {
            const res2 = JSON.parse(JSON.stringify(resArg2));
            const response = res2.treemap.children.map(el => {
              return {
                entity: el.entity,
                sentiment: {
                  count: el.count,
                  sentiment: el.sentiment,
                  sentiment_delta: el.sentiment_delta,
                  volume_ratio: el.volume_ratio
                }
              };
            });
            return response;
          }));
        } else {
          if ((Array.isArray(resArg)) && resArg[0].hasOwnProperty('related_events_participants')) {
            const requestsList = [];
            resArg.forEach(resEl => {
              const respCopy = JSON.parse(JSON.stringify(resEl['related_events_participants']));
              const content = respCopy.map(el => el.entity.compound_key);
              requestsList.push(this.portfolio(iparams, content).pipe(map(resArg2 => {
                const res2 = JSON.parse(JSON.stringify(resArg2));
                const response = res2.treemap.children.map(el => {
                  return {
                    entity: el.entity,
                    sentiment: {
                      count: el.count,
                      sentiment: el.sentiment,
                      sentiment_delta: el.sentiment_delta,
                      volume_ratio: el.volume_ratio
                    }
                  };
                });
                return response;
              })));
            });
            return forkJoin(requestsList);
          } else {
            return throwError('Error: wrong format of the response.');
          }
        }
      }));
    } else {
      return this.iRequest(iparams, params, 'related_entities').pipe(mergeMap(resArg => {
        if (resArg.hasOwnProperty('related_entities')) {
          const respCopy = JSON.parse(JSON.stringify(resArg['related_entities']));
          const content = respCopy.map(el => el.entity.compound_key);
          return this.portfolio(iparams, content).pipe(map(resArg2 => {
            const res2 = JSON.parse(JSON.stringify(resArg2));
            const response = res2.treemap.children.map(el => {
              return {
                entity: el.entity,
                sentiment: {
                  count: el.count,
                  sentiment: el.sentiment,
                  sentiment_delta: el.sentiment_delta,
                  volume_ratio: el.volume_ratio
                }
              };
            });
            return response;
          }));
        } else {
          return throwError('Error: wrong format of the response.');
        }
      }));
    }
  }

  /**
   * list of related events
   */
  relatedEvents(iparams, type): Observable<any> {
    iparams = JSON.parse(JSON.stringify(iparams));
    const paramsToBeSent = ['event_ids', ...this.paramsForAllRequests];
    let params = {};
    params = this.myParams(iparams, paramsToBeSent);
    if (iparams.newsday) {
      params['from'] = moment(iparams.newsday).format('YYYY-MM-DD');
      params['to'] = moment(iparams.newsday).add(1, 'days').format('YYYY-MM-DD');
    }
    return this.iRequest(iparams, params, type).pipe(map((res) => {
      if (type === 'sub_events_itemized') {
        const body = iparams.body ? iparams.body : (this.auth.folio?.content ? this.auth.folio.content : []);
        let respCopy = JSON.parse(JSON.stringify(res));
        if (res.hasOwnProperty('events_itemized')) {
          respCopy = respCopy['events_itemized'];
        }
        const orderedChildren = [];
        body.forEach(el => {
          const filteredEntity = respCopy.filter(item => {
            return item.entity_info.compound_key === el;
          });
          if (filteredEntity.length > 0) {
            // orderedChildren.push(filteredEntity[0]);
            orderedChildren.push(Object.assign({}, filteredEntity[0], {
              entity_info: {
                compound_key: el
              }
            }));
          } else {
            orderedChildren.push({
              entity_info: {
                compound_key: el
              }
            });
            console.log('The entity ' + el + ' is not present in the API response.');
          }
        });
        return orderedChildren.map(item => {
          return {
            entity_info: item.entity_info,
            result: item.events
          };
        });
      } else if (type === 'sub_events') {
        const respCopy = JSON.parse(JSON.stringify(res));
        // if (res.hasOwnProperty('events')) {
        //   respCopy = respCopy['events'];
        // }
        return respCopy;
      } else {
        return res;
      }
    }), catchError(error => of({})));
  }

  /**
   * list of related top sources
   */
  getTopSources(iparams, size): Observable<any> {
    iparams = JSON.parse(JSON.stringify(iparams));
    const paramsToBeSent = ['size', ...this.paramsForAllRequests];
    let params = {};
    params = this.myParams(iparams, paramsToBeSent);
    if (iparams.newsday) {
      params['from'] = moment(iparams.newsday).format('YYYY-MM-DD');
      params['to'] = moment(iparams.newsday).add(1, 'days').format('YYYY-MM-DD');
    }
    if (size) {
      params['size'] = size;
    }
    return this.iRequest(iparams, params, 'top_sources').pipe(mergeMap(respArg => {
      const resp = JSON.parse(JSON.stringify(respArg));
      const sourcesKeys = [];
      resp.top_sources.forEach(source => {
        sourcesKeys.push(source.source.type + ':' + source.source.alpha_id);
      });
      return this.getEntitiesInfo(sourcesKeys).pipe(map(result => {
        resp.top_sources.forEach((source, index) => {
          if (result.hasOwnProperty(resp['top_sources'][index]['source'].type + ':' + resp['top_sources'][index]['source'].alpha_id)) {
            resp['top_sources'][index]['source'].name = result[resp['top_sources'][index]['source'].type + ':' + resp['top_sources'][index]['source'].alpha_id].name;
          }
        });
        return resp;
      }));
    }), catchError(error => of({top_sources: []})));
  }

  /**
  * network of node+link
  */
  network(obj): Observable<any> {
    const paramsToBeSent = [...this.paramsForAllRequests, 'size'];
    const params = this.myParams(obj.params, paramsToBeSent);
    if (obj.custom.size) { params['size'] = obj.custom.size; }
    const options = this.getOption(params);
    if (obj.params.newsday) {
      params['from'] = moment(obj.params.newsday).format('YYYY-MM-DD');
      params['to'] = moment(obj.params.newsday).add(1, 'days').format('YYYY-MM-DD');
    }
    return this.iRequest(obj.params, params, 'network');
  }

  /**
  * data from specific company
  */
  relations(iparams, types): Observable<any> {
    if (iparams.type && iparams.id) {
      const entity_type = iparams['type'];
      const entity_id = iparams['id'];
      const compoundKey = entity_type + ':' + entity_id;
      const requestMetadataList = [];
      requestMetadataList.push(this.getEntitiesInfo([compoundKey], 'array'));
      types.forEach(type => {
        requestMetadataList.push(this.getRelatedEntitiesInfo(entity_type, entity_id, type));
      });
      return forkJoin(requestMetadataList).pipe(mergeMap(resArg => {
        const res = JSON.parse(JSON.stringify(resArg));
        const requestSentimentList = [];
        // if (!(['industry', 'supersector', 'sector', 'subsector'].includes(iparams.type))) {
        //   res.forEach(item => {
        //     const entitiesList = [];
        //     // @ts-ignore
        //     item.forEach(el => {
        //       entitiesList.push(el.compound_key);
        //     });
        //     requestSentimentList.push(this.sentimentItemized(iparams, entitiesList));
        //   });
        //   res.forEach((item, index) => {
        //     if (index !== 0) {
        //       const entitiesList = [];
        //       // @ts-ignore
        //       item.forEach(el => {
        //         entitiesList.push(el.compound_key);
        //       });
        //       requestSentimentList.push(this.portfolioSentiment(iparams, entitiesList, false));
        //     }
        //   });
        // }
        res.forEach(item => {
          const entitiesList = [];
          // @ts-ignore
          item.forEach(el => {
            entitiesList.push(el.compound_key);
          });
          requestSentimentList.push(this.sentimentItemized(iparams, entitiesList));
        });
        res.forEach((item, index) => {
          if (index !== 0) {
            const entitiesList = [];
            // @ts-ignore
            item.forEach(el => {
              entitiesList.push(el.compound_key);
            });
            requestSentimentList.push(this.portfolioSentiment(iparams, entitiesList, false));
          }
        });
        return forkJoin(requestSentimentList).pipe(map(resArg2 => {
          const res2 = JSON.parse(JSON.stringify(resArg2));
          const relationTypes = {};
          types.forEach((type, index) => {
            relationTypes[type] = Object.assign({}, {
              // @ts-ignore
              children: res[index + 1].map((el, index2) => {
                return Object.assign({}, {
                  entity: res[index + 1][index2]
                }, res2[index + 1][el.compound_key]);
              }),
              name: type[0].toUpperCase() + type.slice(1),
              entity: {
                alpha_id: type,
                name: type[0].toUpperCase() + type.slice(1),
                type: 'relations'
              }
            }, res2[types.length + 1 + index]['sentiment']);
          });
          const relationsObject = Object.assign({}, res2[0][compoundKey], {
            entity: res[0][0],
            relations: relationTypes
          });
          return relationsObject;
        }));
      }));
    } else {
      return of({
        entity: {},
        relations: {}
      });
    }
    // if (iparams.query) {
    //   const requestUrl = `${this.apiurl}/api/search_query/relations`;
    //   return this.http.post(requestUrl, iparams.query, options);
    // }
  }

  /**
   * peergroup comparison for companies including scores
   */
  peergroupComparison(iparams): Observable<any> {
    if (iparams && iparams.id && iparams.type && (iparams.type === 'company')) {
      return this.getPeergroup(iparams).pipe(mergeMap(resArg => {
        const res = JSON.parse(JSON.stringify(resArg));
        const content = res.companies.map(el => el.compound_key);
        content.push(iparams.type + ':' + iparams.id);
        return this.getEntitiesInfo(content, 'array').pipe(mergeMap(resArg2 => {
          return this.sentimentItemized(iparams, content).pipe(map(resArg3 => {
            return resArg2.map(el => {
              return Object.assign({}, el, resArg3[el.compound_key]);
            });
          }));
        }));
      }));
    } else {
      return of([]);
    }
  }

  /**
  * sentiment of specific entity
  */
  isentiment(iparams, newsday): Observable<any> {
    const paramsToBeSent = ['period', ...this.paramsForAllRequests];
    const params = this.myParams(iparams, paramsToBeSent);
    if (newsday && iparams.newsday) {
      params['from'] = moment(iparams.newsday).format('YYYY-MM-DD');
      params['to'] = moment(iparams.newsday).add(1, 'days').format('YYYY-MM-DD');
    }
    if (iparams.type && iparams.id) {
      return this.iRequest(iparams, params, 'sentiment').pipe(mergeMap(resArg => {
        const res = JSON.parse(JSON.stringify(resArg));
        if (res.entity && res.entity.compound_key) {
          return this.getEntitiesInfo([res.entity.compound_key], 'object', (iparams.getSanctions ? true : false)).pipe(map(resArg2 => {
            const res2 = JSON.parse(JSON.stringify(resArg2));
            return {
              entity: res2[res.entity.compound_key],
              sentiment: res.sentiment
            };
          }));
        } else {
          return of(res);
        }
      }));
    } else {
      return this.iRequest(iparams, params, 'sentiment');
    }
  }

  /**
   * sentiment of a portfolio
   */
  portfolioSentiment(iparams, portfolio, newsday): Observable<any> {
    const paramsToBeSent = ['period', ...this.paramsForAllRequests];
    let params = {};
    if (iparams) { params = this.myParams(iparams, paramsToBeSent); }
    if (newsday && iparams.newsday) {
      params['from'] = moment(iparams.newsday).format('YYYY-MM-DD');
      params['to'] = moment(iparams.newsday).add(1, 'days').format('YYYY-MM-DD');
    }
    const options = this.getOption(params);
    const requestUrl = `${this.apiurl}/api/portfolio/sentiment`;
    return this.http.post(requestUrl, portfolio, options);
  }

  /**
   * entities of specific portfolio
   */
  sentimentItemized(iparams, portfolio): Observable<any> {
    const paramsToBeSent = ['period', ...this.paramsForAllRequests];
    let params = {};
    if (iparams) { params = this.myParams(iparams, paramsToBeSent); }
    const options = this.getOption(params);
    const requestUrl = `${this.apiurl}/v2/api/portfolio/sentiment_itemized`;
    return this.http.post(requestUrl, portfolio, options).pipe(map(resp => {
      if (resp.hasOwnProperty('sentiment_itemized')) {
        const respCopy = JSON.parse(JSON.stringify(resp['sentiment_itemized']));
        const entitiesData = {};
        portfolio.forEach(el => {
          const filteredEntity = respCopy.filter(item => {
            return item.entity.compound_key === el;
          });
          if (filteredEntity.length > 0) {
            entitiesData[el] = filteredEntity[0].sentiment;
          } else {
            console.log('The entity ' + el + ' is not present in the API response.');
          }
        });
        return entitiesData;
      } else {
        return throwError('Error: wrong format of the response.');
      }
    }));
  }

  /**
   * sentiment of many entities/portoflios/collections, not used for entities in cockpit
   */
  isentimentArray(iparams, newsday): Observable<any> {
    const paramsToBeSent = ['period', ...this.paramsForAllRequests];
    const params = this.myParams(iparams, paramsToBeSent);
    if (newsday && iparams.newsday) {
      params['from'] = moment(iparams.newsday).format('YYYY-MM-DD');
      params['to'] = moment(iparams.newsday).add(1, 'days').format('YYYY-MM-DD');
    }
    return this.iRequest(Object.assign({}, iparams, {array: true}), params, 'sentiment');
  }

  /**
  * envent list of specific entity
  */
  ievent(iparams, score = null): Observable<any> {
    const paramsToBeSent = ['factuality', 'temporality', ...this.paramsForAllRequests];
    const params = this.myParams(iparams, paramsToBeSent);
    if (iparams.eventype && iparams.eventid && iparams.eventype !== 'sub_event') {
      const fixit = iparams.eventype + 's';
      params[fixit] = iparams.eventid;
    }
    if (score && !['sentiment', 'bbd', 'bbw', 'bbm', 'bbq'].includes(score)) {
      return this.scoreWeights(iparams, score).pipe(mergeMap(resArg => {
        const res = JSON.parse(JSON.stringify(resArg));
        const filterEvents = res.join(',');
        params['event_ids'] = filterEvents;
        return this.iRequest(iparams, params, 'events_ts').pipe(map(resp => {
          if (resp.hasOwnProperty('events_ts')) {
            const respCopy = JSON.parse(JSON.stringify(resp));
            return respCopy['events_ts'];
          } else {
            return throwError('Error: wrong format of the response.');
          }
        }));
      }));
    } else {
      return this.iRequest(iparams, params, 'events_ts').pipe(map(resp => {
        if (resp.hasOwnProperty('events_ts')) {
          const respCopy = JSON.parse(JSON.stringify(resp));
          return respCopy['events_ts'];
        } else {
          return throwError('Error: wrong format of the response.');
        }
      }));
    }
  }

  /**
  * multiple serie of portfolio or query
  */
  trendradar(iparams): Observable<any> {
    const paramsToBeSent = ['period', ...this.paramsForAllRequests];
    const params = this.myParams(iparams, paramsToBeSent);
    return this.iRequest(iparams, params, 'multi_trend_radar');
  }

  /**
   * peergroup companies
   */
  getPeergroup(iparams): Observable<any> {
    const paramsToBeSent = ['period', ...this.paramsForAllRequests];
    const params = this.myParams(iparams, paramsToBeSent);
    const options = this.getOption(params);
    const requestUrl = this.apiurlQuant + `/misc/company/${iparams.id}/peergroup`;
    return this.http.get(requestUrl, options);
  }

  /**
  * supersector list
  */
  supersector_etf(): Observable<any> {
    const options = this.getOption(null);
    const params = `/trading/supersector_etf`;
    const requestUrl = this.apiurlQuant + params;
    return this.http.get(requestUrl, options);
  }

  /**
  * signal list
  */
  signal(supersector_etf_id): Observable<any> {
    const options = this.getOption(null);
    const params = `/trading/supersector_etf/${supersector_etf_id}/signal`;
    const requestUrl = this.apiurlQuant + params;
    return this.http.get(requestUrl, options);
  }

  /**
  * trend index etf
  */
  index_etf(): Observable<any> {
    const options = this.getOption(null);
    const params = `/trading/index_etf`;
    const requestUrl = this.apiurlQuant + params;
    return this.http.get(requestUrl, options);
  }

  /**
  * sentiment active endpoint
  */
  sentiment_active(index_etf_id, overlay): Observable<any> {
    const options = this.getOption({ overlay: overlay });
    const params = `/trading/index_etf/${index_etf_id}/sentiment_active`;
    const requestUrl = this.apiurlQuant + params;
    return this.http.get(requestUrl, options);
  }

  /**
   * trend lab signals' list
   */
  getSignalsList(): Observable<any> {
    const options = this.getOption(null);
    const params = `/signal/signal`;
    const requestUrl = this.apiurlQuant + params;
    return this.http.get(requestUrl, options);
  }

  /**
   * signals' data endpoint
   */
  getSignalData(type): Observable<any> {
    const options = this.getOption(null);
    const params = `/signal/${type}`;
    const requestUrl = this.apiurlQuant + params;
    return this.http.get(requestUrl, options);
  }

  /**
  * get time series of different score types
  */
  scoresTimeSeries(type, iparams, chart) {
    const paramsToBeSent = ['period', 'with_top_events', 'with_all_events', ...this.paramsForAllRequests];
    let params = {};
    params = this.myParams(iparams, paramsToBeSent);
    if (chart === 'izoom') {
      params['from'] = this.myFromTo(this.config.appConfig.setup.startDate).date1;
      params['to'] = moment().add(1, 'days').format('YYYY-MM-DD');
      params['izoom'] = true;
    }
    if (type === 'sentiment') {
      params['algorithm'] = 'rolling';
      if (iparams.itemized) {
        return this.iRequest(iparams, params, 'quotient_ts_itemized');
      } else {
        return this.iRequest(iparams, params, 'quotient_ts');
      }
    }
    if (type === 'volume') {
      delete params['period'];
      return this.iRequest(iparams, params, 'volume_ts');
    }
    if (type === 'score' || type === 'scoreE' || type === 'scoreS' || type === 'scoreG') {
      if (iparams.scorePortfolio) {
        params['from'] = moment(params['to']).subtract(1, 'days').format('YYYY-MM-DD');
      }
      params['period'] = 30;
      if (iparams.document_category_filter) {
        delete params['document_category_filter'];
      }
      if (iparams.event_ids) {
        delete params['event_ids'];
      }

      if (iparams.requestScore) {
        if (iparams.requestScore === 'general_risk') {
          params['score_type'] = 'general_risk';
          params['with_black_swan'] = true;
          // params['document_category_filter'] = 'business,business_pro_forma';
        } else if (iparams.requestScore === 'credit_risk') {
          params['score_type'] = 'credit_watch';
          params['risk_sensitivity'] = 0;
        } else if (iparams.requestScore === 'esg_risk') {
          params['score_type'] = 'esg_risk';
          params['with_black_swan'] = true;
          // params['document_category_filter'] = 'business,business_pro_forma';
        } else if (iparams.requestScore === 'immediate_risk') {
          params['score_type'] = 'immediate_risk';
          params['with_black_swan'] = true;
        } else if (iparams.requestScore === 'esg') {
          params['score_type'] = 'esg';
          params['disable_industry_materiality'] = true;
        } else if (iparams.requestScore === 'eb_sim') {
          params['score_type'] = 'eb_sim';
        } else if (iparams.requestScore === 'bbd') {
          params['time_horizon'] = '1D';
        } else if (iparams.requestScore === 'bbw') {
          params['time_horizon'] = '1W';
        } else if (iparams.requestScore === 'bbm') {
          params['time_horizon'] = '1M';
        } else if (iparams.requestScore === 'bbq') {
          params['time_horizon'] = '3M';
        } else {
          const defaultScoreObj = this.auth.scorelabSettings.customScores.filter(score => {
            return score.uid === iparams.requestScore;
          })[0];
          if (defaultScoreObj?.sentiment_type) {
            if (defaultScoreObj.sentiment_type === 'neg') {
              params['with_black_swan'] = true;
            }
          }
          if (defaultScoreObj?.event_weights && (defaultScoreObj?.event_weights.length > 0)) {
            defaultScoreObj.event_weights.forEach(event => {
              let event_id, weight, polarity, weight_persistence;
              if (event.event_id) {
                event_id = event.event_id;
              } else {
                event_id = undefined;
              }
              if (event.weight) {
                weight = event.weight;
              } else {
                weight = 5;
              }
              if (event.polarity) {
                polarity = event.polarity;
              } else {
                polarity = -1;
              }
              if (event.weight_persistence) {
                weight_persistence = event.weight_persistence;
              } else {
                weight_persistence = 90;
              }
              if (event_id) {
                params[event_id + '_weight'] = (weight * polarity);
              }
            });
          }
        }
      } else if (iparams.score_type && (iparams.score_type !== 'credit_risk') && (iparams.score_type !== 'bbd') && (iparams.score_type !== 'bbw') && (iparams.score_type !== 'bbm') && (iparams.score_type !== 'bbq')) {
        params['score_type'] = iparams.score_type;
        if ((iparams.score_type === 'esg') || (iparams.score_type === 'e_standard') || (iparams.score_type === 's_standard') || (iparams.score_type === 'g_standard')) {
          params['disable_industry_materiality'] = true;
        }
      } else if (type === 'scoreE') {
        params['score_type'] = 'e_standard';
        params['disable_industry_materiality'] = true;
      } else if (type === 'scoreS') {
        params['score_type'] = 's_standard';
        params['disable_industry_materiality'] = true;
      } else if (type === 'scoreG') {
        params['score_type'] = 'g_standard';
        params['disable_industry_materiality'] = true;
      } else if (this.auth.scorelabSettings.defaultScore === 'general_risk') {
        params['score_type'] = 'general_risk';
        params['with_black_swan'] = true;
        // params['document_category_filter'] = 'business,business_pro_forma';
      } else if (this.auth.scorelabSettings.defaultScore === 'esg_risk') {
        params['score_type'] = 'esg_risk';
        params['with_black_swan'] = true;
        // params['document_category_filter'] = 'business,business_pro_forma';
      } else if (this.auth.scorelabSettings.defaultScore === 'immediate_risk') {
        params['score_type'] = 'immediate_risk';
        params['with_black_swan'] = true;
      } else if (this.auth.scorelabSettings.defaultScore === 'credit_risk') {
        params['score_type'] = 'credit_watch';
        params['risk_sensitivity'] = 0;
      } else if (this.auth.scorelabSettings.defaultScore === 'esg') {
        params['score_type'] = 'esg';
        params['disable_industry_materiality'] = true;
        // params['use_volume'] = false;
        // params['sentiment_weight'] = 0;
      } else if (this.auth.scorelabSettings.defaultScore === 'eb_sim') {
        params['score_type'] = 'eb_sim';
      } else if (this.auth.scorelabSettings.defaultScore === 'bbd') {
        params['time_horizon'] = '1D';
      } else if (this.auth.scorelabSettings.defaultScore === 'bbw') {
        params['time_horizon'] = '1W';
      } else if (this.auth.scorelabSettings.defaultScore === 'bbm') {
        params['time_horizon'] = '1M';
      } else if (this.auth.scorelabSettings.defaultScore === 'bbq') {
        params['time_horizon'] = '3M';
      } else if (this.auth.scorelabSettings.defaultScore && (this.auth.scorelabSettings.defaultScore !== 'credit_risk') && (this.auth.scorelabSettings.defaultScore !== 'bbd') && (this.auth.scorelabSettings.defaultScore !== 'bbw') && (this.auth.scorelabSettings.defaultScore !== 'bbm') && (this.auth.scorelabSettings.defaultScore !== 'bbq')) {
        // params['use_volume'] = false;
        const defaultScoreObj = this.auth.scorelabSettings.customScores.filter(score => {
          return score.uid === this.auth.scorelabSettings.defaultScore;
        })[0];
        if (defaultScoreObj.sentiment_type) {
          // params['sentiment_type'] = defaultScoreObj.sentiment_type;
          if (defaultScoreObj.sentiment_type === 'neg') {
            params['with_black_swan'] = true;
          }
        } else {
          // params['sentiment_type'] = 'neg';
        }
        // if (defaultScoreObj.sentiment_weight) {
        //   // params['sentiment_weight'] = defaultScoreObj.sentiment_weight;
        //   params['sentiment_weight'] = 0;
        // } else {
        //   params['sentiment_weight'] = 0;
        // }
        if (defaultScoreObj.event_weights && (defaultScoreObj.event_weights.length > 0)) {
          defaultScoreObj.event_weights.forEach(event => {
            let event_id, weight, polarity, weight_persistence;
            if (event.event_id) {
              event_id = event.event_id;
            } else {
              event_id = undefined;
            }
            if (event.weight) {
              weight = event.weight;
            } else {
              weight = 5;
            }
            if (event.polarity) {
              polarity = event.polarity;
            } else {
              polarity = -1;
            }
            if (event.weight_persistence) {
              weight_persistence = event.weight_persistence;
            } else {
              weight_persistence = 90;
            }
            if (event_id) {
              params[event_id + '_weight'] = (weight * polarity);
            }
          });
        }
      }
      if ((!iparams.requestScore && ['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultScore)) || (iparams.requestScore && ['bbd', 'bbw', 'bbm', 'bbq'].includes(iparams.requestScore))) {
        if (iparams.itemized) {
          return this.iRequest(iparams, params, 'bull_bear_score_ts_itemized').pipe(map(respArg => {
            const resp = JSON.parse(JSON.stringify(respArg));
            if (resp.hasOwnProperty('bull_bear_score_ts_itemized') && resp['bull_bear_score_ts_itemized'][0]?.hasOwnProperty('bull_bear_scores')) {
              let mappedResp = resp['bull_bear_score_ts_itemized'];
              const respCopy = JSON.parse(JSON.stringify(resp['bull_bear_score_ts_itemized']));
              if (iparams.custom_payload || this.auth.folio.content) {
                const orderedChildren = [];
                let body = [];
                if (iparams.custom_payload) {
                  body = iparams.custom_payload;
                } else {
                  body = this.auth.folio.content;
                }
                body.forEach(el => {
                  const filteredEntity = respCopy.filter(item => {
                    return item.entity_info.compound_key === el;
                  });
                  if (filteredEntity.length > 0) {
                    // orderedChildren.push(filteredEntity[0]);
                    orderedChildren.push(Object.assign({}, filteredEntity[0], {
                      entity_info: {
                        compound_key: el
                      }
                    }));
                  } else {
                    orderedChildren.push({
                      entity_info: {
                        compound_key: el
                      }
                    });
                    console.log('The entity ' + el + ' is not present in the API response.');
                  }
                });
                mappedResp = orderedChildren;
              }
              // @ts-ignore
              return mappedResp.map(el => {
                if (el && el.hasOwnProperty('bull_bear_scores') && (el['bull_bear_scores'].length > 0)) {
                  const trend_type = (el['bull_bear_scores'][el['bull_bear_scores'].length - 1].bull_bear_score > 5.0) ? 'bull' : 'bear';
                  const trend_value = (el['bull_bear_scores'][el['bull_bear_scores'].length - 1].bull_bear_score).toFixed(1);
                  return {
                    entity_info: el['entity_info'],
                    // score_type: el['score_type'],
                    score_ts: el['bull_bear_scores'].map(el2 => {
                      const trend_type2 = (el2.bull_bear_score > 5.0) ? 'bull' : 'bear';
                      const trend_value2 = (el2.bull_bear_score).toFixed(1);
                      return {
                        date: el2.date,
                        score: el2.bull_bear_score,
                        trend_type: trend_type2,
                        trend_value: trend_value2
                      };
                    }),
                    score_ts_last_score: {
                      date: el['bull_bear_scores'][el['bull_bear_scores'].length - 1].date,
                      score: el['bull_bear_scores'][el['bull_bear_scores'].length - 1].bull_bear_score,
                      trend_type: trend_type,
                      trend_value: trend_value
                    }
                  };
                } else {
                  return undefined;
                }
              });
            } else {
              return resp;
            }
          }));
        } else {
          return this.iRequest(iparams, params, 'bull_bear_score_ts').pipe(map(respArg => {
            const resp = JSON.parse(JSON.stringify(respArg));
            if (resp.hasOwnProperty('bull_bear_scores') && (resp['bull_bear_scores'].length > 0)) {
              const trend_type = (resp['bull_bear_scores'][resp['bull_bear_scores'].length - 1].bull_bear_score > 5.0) ? 'bull' : 'bear';
              const trend_value = (resp['bull_bear_scores'][resp['bull_bear_scores'].length - 1].bull_bear_score).toFixed(1);
              return {
                // score_type: resp['score_type'],
                score_ts: resp['bull_bear_scores'].map(el => {
                  const trend_type2 = (el.bull_bear_score > 5.0) ? 'bull' : 'bear';
                  const trend_value2 = (el.bull_bear_score).toFixed(1);
                  return {
                    date: el.date,
                    score: el.bull_bear_score,
                    trend_type: trend_type2,
                    trend_value: trend_value2
                  };
                }),
                score_ts_last_score: {
                  date: resp['bull_bear_scores'][resp['bull_bear_scores'].length - 1].date,
                  score: resp['bull_bear_scores'][resp['bull_bear_scores'].length - 1].bull_bear_score,
                  trend_type: trend_type,
                  trend_value: trend_value
                }
              };
            } else {
              return resp;
            }
          }));
        }
      } else if (iparams.industry_bench) {
        // delete params['disable_industry_materiality'];
        if (iparams.itemized) {
          return this.iRequest(iparams, params, 'score_ts_industry_bench_itemized').pipe(map(respArg => {
            const resp = JSON.parse(JSON.stringify(respArg));
            if (resp.hasOwnProperty('score_ts_industry_bench_itemized')) {
              if (resp['score_ts_industry_bench_itemized'][0]?.hasOwnProperty('score_ts_industry_bench')) {
                let mappedResp = resp['score_ts_industry_bench_itemized'];
                const respCopy = JSON.parse(JSON.stringify(resp['score_ts_industry_bench_itemized']));
                if (iparams.custom_payload || this.auth.folio.content) {
                  const orderedChildren = [];
                  let body = [];
                  if (iparams.custom_payload) {
                    body = iparams.custom_payload;
                  } else {
                    body = this.auth.folio.content;
                  }
                  body.forEach(el => {
                    const filteredEntity = respCopy.filter(item => {
                      return item.entity_info.compound_key === el;
                    });
                    if (filteredEntity.length > 0) {
                      // orderedChildren.push(filteredEntity[0]);
                      orderedChildren.push(Object.assign({}, filteredEntity[0], {
                        entity_info: {
                          compound_key: el
                        }
                      }));
                    } else {
                      orderedChildren.push({
                        entity_info: {
                          compound_key: el
                        }
                      });
                      console.log('The entity ' + el + ' is not present in the API response.');
                    }
                  });
                  mappedResp = orderedChildren;
                }
                // @ts-ignore
                return mappedResp.map(el => {
                  return {
                    entity_info: el['entity_info'],
                    // score_type: el['score_type'],
                    score_ts: el['score_ts_industry_bench'],
                    score_ts_last_score: el['score_ts_industry_bench_last_score']
                  };
                });
              } else {
                return resp['score_ts_industry_bench_itemized'];
              }
            } else {
              return throwError('Error: wrong format of the response.');
            }
          }));
        } else {
          return this.iRequest(iparams, params, 'score_ts_industry_bench').pipe(map(respArg => {
            const resp = JSON.parse(JSON.stringify(respArg));
            if (resp.hasOwnProperty('score_ts_industry_bench')) {
              return {
                // score_type: resp['score_type'],
                score_ts: resp['score_ts_industry_bench'],
                score_ts_last_score: resp['score_ts_industry_bench_last_score']
              };
            } else {
              return resp;
            }
          }));
        }
      } else {
        if (iparams.with_top_events) {
          if ((!iparams.requestScore && (this.auth.scorelabSettings.defaultScore === 'credit_risk')) || (iparams.requestScore && (iparams.requestScore === 'credit_risk') && !iparams.industry_bench)) {
            if (iparams.top_events_sign === 'neg') {
              params['top_events_sign'] = iparams.top_events_sign;
            }
          }
          delete params['with_top_events'];
          return forkJoin({count: this.iRequest(iparams, Object.assign({}, params, {top_events_ranking: 'absolute_count'}), 'score_ts_top_events'), importance: this.iRequest(iparams, Object.assign({}, params, {top_events_ranking: 'relative_importance'}), 'score_ts_top_events')}).pipe(map(resp => {
            const respCopy = JSON.parse(JSON.stringify(resp));
            const topEvents = respCopy.count;
            topEvents.top_events.forEach((event, index) => {
              const importanceEventInstance = respCopy.importance.top_events?.filter(e => e.id === event.id)[0];
              topEvents.top_events[index].contrib_amount_importance = importanceEventInstance ? importanceEventInstance.contrib_amount : null;
            });
            return topEvents;
          }));
        } else if (iparams.itemized) {
          return this.iRequest(iparams, params, 'score_ts_itemized').pipe(map(respArg => {
            const resp = JSON.parse(JSON.stringify(respArg));
            if (resp.hasOwnProperty('score_ts_itemized')) {
              if (resp['score_ts_itemized'][0]?.hasOwnProperty('score_ts')) {
                let mappedResp = resp['score_ts_itemized'];
                const respCopy = JSON.parse(JSON.stringify(resp['score_ts_itemized']));
                if (iparams.custom_payload || this.auth.folio.content) {
                  const orderedChildren = [];
                  let body = [];
                  if (iparams.custom_payload) {
                    body = iparams.custom_payload;
                  } else {
                    body = this.auth.folio.content;
                  }
                  body.forEach(el => {
                    const filteredEntity = respCopy.filter(item => {
                      return item.entity_info.compound_key === el;
                    });
                    if (filteredEntity.length > 0) {
                      // orderedChildren.push(filteredEntity[0]);
                      orderedChildren.push(Object.assign({}, filteredEntity[0], {
                        entity_info: {
                          compound_key: el
                        }
                      }));
                    } else {
                      orderedChildren.push({
                        entity_info: {
                          compound_key: el
                        }
                      });
                      console.log('The entity ' + el + ' is not present in the API response.');
                    }
                  });
                  mappedResp = orderedChildren;
                }
                // @ts-ignore
                return mappedResp;
              } else {
                return resp['score_ts_itemized'];
              }
            } else {
              return throwError('Error: wrong format of the response.');
            }
          }));
        } else {
          return this.iRequest(iparams, params, 'score_ts');
        }
      }
    }
    if (type === 'price') {
      delete params['period'];
      delete params['project'];
      delete params['feed_types'];
      delete params['lang'];
      const options = this.getOption(params);
      const requestUrl = `${this.apiurlQuant}/trading/${iparams['type']}/${iparams['id']}/yl_price`;
      return this.http.get(requestUrl, options).pipe(timeout(2000), catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.error('An error occurred:', err.error.message);
        } else {
          console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
        }
        return of({prices: [], currency: []});
      }));
    }
  }

  /**
   * get score related events and weights
   */
  scoreWeights(iparams, score) {
    const params = {};
    if (['bbd', 'bbw', 'bbm', 'bbq', 'sentiment'].includes(score)) {
      return of([]);
    } else if (['general_risk', 'credit_risk', 'esg_risk', 'immediate_risk', 'esg', 'eb_sim'].includes(score)) {
      if (score === 'general_risk') {
        params['score_type'] = 'general_risk';
      } else if (score === 'credit_risk') {
        params['score_type'] = 'credit_watch';
      } else if (score === 'esg_risk') {
        params['score_type'] = 'esg_risk';
      } else if (score === 'immediate_risk') {
        params['score_type'] = 'immediate_risk';
      } else if (score === 'esg') {
        params['score_type'] = 'esg';
        params['disable_industry_materiality'] = true;
      } else if (score === 'eb_sim') {
        params['score_type'] = 'eb_sim';
      }
      return this.iRequest(iparams, params, 'score_ts_weights').pipe(map(res => {
        if (score === 'credit_risk') {
          const negativeEvents = [];
          for (const [key, value] of Object.entries(res['weights'].event_id)) {
            if (res['weights'].polarity[key] === -1) {
              negativeEvents.push(value);
            }
          }
          return negativeEvents;
        } else {
          return Object.values(res['weights'].event_id);
        }
      }));
    } else {
      const customScoreObj = this.auth.scorelabSettings.customScores.filter(el => {
        return el.uid === score;
      })[0];
      return of(customScoreObj.event_weights.map(el => el.event_id));
    }
  }

  /**
  * search data from a query
  */
  search_query(rules): Observable<any> {
    const options = this.getOption(null);
    const requestUrl = `${this.apiurl}/api/search_query`;
    return this.http.post(requestUrl, rules, options);
  }

  /**
  * file to export and save
  */
  getMulty(iparams, format, what) {
    const paramsToBeSent = ['period', ...this.paramsForAllRequests];
    const params = this.myParams(iparams, paramsToBeSent);
    params['format'] = format;
    params['what'] = what;
    const options = this.getOption(params);
    options['responseType'] = 'blob';
    let requestUrl;
    // if (iparams.portfolio) {
    //   requestUrl = `${this.apiurl}/api/portfolio/multi_entity_download`;
    //   const entities = iparams['entities'] ? String(params['entities']).split(',') : [];
    //   return this.http.post(requestUrl, entities, options);
    // }
    if (iparams['type'] && iparams['id']) {
      requestUrl = `${this.apiurl}/api/${iparams['type']}/${iparams['id']}/multi_entity_download`;
      return this.http.get(requestUrl, options);
    }
    // if (iparams['query']) {
    //   requestUrl = `${this.apiurl}/api/search_query/multi_entity_download`;
    //   return this.http.post(requestUrl, iparams['query'], options);
    // }
    if (this.routing.isFolio()) {
      requestUrl = `${this.apiurl}/api/portfolio/multi_entity_download`;
      const entities = this.auth.folio ? this.auth.folio.content : [];
      return this.http.post(requestUrl, entities, options);
    }
    if (this.routing.isQuery() || this.routing.isSearch()) {
      requestUrl = `${this.apiurl}/api/search_query/multi_entity_download`;
      const queryObj = this.auth.query ? this.auth.query.query : {};
      return this.http.post(requestUrl, queryObj, options);
    }
  }

  /**
  * parse isin list
  */
  isinParse(isins): Observable<any> {
    const options = this.getOption(null);
    const requestUrl = `${this.apiurlMetadata}/isin_to_entity`;
    return this.http.post(requestUrl, isins, options);
  }

  /**
   * get more detailed info about entities based on compound_key value
   */
  getEntitiesInfo(entitiesArray, format = 'object', sanctions = false): Observable<any> {
    let options;
    if ((this.routing.isReader() || this.routing.isStoryReader()) && this.auth.token) {
      options = this.getOption({jwt: this.auth.token});
    } else {
      options = this.getOption(null);
    }
    const requestUrl = `${this.apiurlMetadata}/entity_info`;

    return forkJoin([this.http.post(requestUrl, entitiesArray, options), (sanctions ? this.getSanctionsInfo(entitiesArray) : of({sanctions: []}))]).pipe(map(res => {
      const respCopy = JSON.parse(JSON.stringify(res[0]));
      const sanctionsCopy = JSON.parse(JSON.stringify(res[1]));
      respCopy.forEach((entity, index) => {
        const sanctionsArray = [];
        sanctionsCopy['sanctions'].forEach(s => {
          if (entity.compound_key === s.sanctioned_entity.compound_key) {
            sanctionsArray.push(s);
          }
        });
        respCopy[index]['sanctions'] = sanctionsArray;
      });
      entitiesArray.forEach(el => {
        const filteredEntity = respCopy.filter(item => {
          return item.compound_key === el;
        });
        if (!(filteredEntity.length > 0)) {
          console.log('The entity ' + el + ' is not present in the metadata API response.');
        }
      });
      if (format === 'object') {
        const resObject = {};
        // @ts-ignore
        respCopy.forEach(el => {
          resObject[el.compound_key] = el;
        });
        return resObject;
      } else {
        return respCopy;
      }
    }), catchError(error => throwError(error)));
  }

  /**
   * get info about sanctioned entities based on the compound_key value
   */
  getSanctionsInfo(entitiesArray) {
    const options = this.getOption(null);
    const requestUrl = `${this.apiurlMetadata}/sanctions/entity`;
    return this.http.post(requestUrl, entitiesArray, options).pipe(map(res => res), catchError(error => {
      console.log(error);
      return of({sanctions: []});
    }));
  }

  /**
   * get related entities info
   */
  getRelatedEntitiesInfo(type, id, filter): Observable<any> {
    const options = this.getOption(null);
    const requestUrl = `${this.apiurlMetadata}/related/${type}.${id}?entity_type_filter=${filter}`;
    return this.http.get(requestUrl, options);
  }

  /**
   * get top related entities
   */
  getTopRelatedEntities(type, id, size): Observable<any> {
    const options = this.getOption(null);
    const requestUrl = `${this.apiurlMetadata}/approximation/${type}.${id}?limit=${size}`;
    const parentEntity = type + ':' + id;
    return forkJoin([this.getEntitiesInfo([parentEntity]), this.http.get(requestUrl, options)]).pipe(map(resArg => {
      const res = JSON.parse(JSON.stringify(resArg));
      return Object.assign({}, res[1], {
        entity: res[0][parentEntity]
      });
    }));
  }

  /**
   * get hierarchy info
   */
  getHierarchyInfo(type): Observable<any> {
    const options = this.getOption(null);
    if (['icb', 'event'].includes(type)) {
      const requestUrl = `${this.apiurlMetadata}/hierarchy/${type}`;
      return this.http.get(requestUrl, options);
    } else {
      // const requestUrl = `${this.apiurlQuant}/signal/market_board?board_type=${type}&score_type=${this.auth.scorelabSettings.defaultScore}`;
      const requestUrl = `${this.apiurlQuant}/signal/market_board?board_type=${type}`;
      return this.http.get(requestUrl, options);
    }
  }

  /**
   * get entity types list
   */
  getEntityTypes(): Observable<any> {
    const options = this.getOption(null);
    const requestUrl = `${this.apiurlMetadata}/grouping_info/entity_types`;
    return this.http.get(requestUrl, options);
  }

  /**
   * get subsectors list
   */
  getSubsectors(): Observable<any> {
    const options = this.getOption(null);
    const requestUrl = `${this.apiurlMetadata}/grouping_info/subsectors`;
    return this.http.get(requestUrl, options);
  }

  /**
   * get countries list
   */
  getCountries(): Observable<any> {
    const options = this.getOption(null);
    const requestUrl = `${this.apiurlMetadata}/grouping_info/countries`;
    return this.http.get(requestUrl, options);
  }

  /**
   * get continents list
   */
  getContinents(): Observable<any> {
    const options = this.getOption(null);
    const requestUrl = `${this.apiurlMetadata}/grouping_info/continents`;
    return this.http.get(requestUrl, options);
    // return of({entities: [{alpha_id: 'af', name: 'Africa', type: 'continent'}, {alpha_id: 'an', name: 'Antarctica', type: 'continent'}, {alpha_id: 'as', name: 'Asia', type: 'continent'}, {alpha_id: 'eu', name: 'Europe', type: 'continent'}, {alpha_id: 'middle_east', name: 'Middle East', type: 'continent'}, {alpha_id: 'na', name: 'North America', type: 'continent'}, {alpha_id: 'oc', name: 'Oceania', type: 'continent'}, {alpha_id: 'sa', name: 'South America', type: 'continent'}]});
  }

  /**
  * file to export and save
  */
  // exportEvent(iparams, endpoint): Observable<any> {
  //   const paramsToBeSent = [...this.paramsForAllRequests];
  //   const params = this.myParams(iparams, paramsToBeSent);
  //   params['num_entities_events'] = 100;
  //   params['num_news'] = 100;
  //   if (iparams.newsaudit) {
  //     params['audited_only'] = 'true';
  //   }
  //   const options = this.getOption(params);
  //   options['responseType'] = 'blob';
  //   if (endpoint === 'folio') {
  //     const requestUrl = this.apiurl + '/api/portfolio/news_event_export';
  //     return this.http.post(requestUrl, this.auth.folio.content, options);
  //   } else if (endpoint === 'query') {
  //     const requestUrl = this.apiurl + '/api/search_query/news_event_export';
  //     return this.http.post(requestUrl, this.auth.query.query, options);
  //   } else if (endpoint === 'entity') {
  //     const requestUrl = this.apiurl + '/api/' + iparams.type + '/' + iparams.id + '/news_event_export';
  //     return this.http.get(requestUrl, options);
  //   }
  // }

}
