import { Component, ViewEncapsulation, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { RoutingService } from 'src/app/service/routing.service';
import { Location } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { Observable, empty, of, forkJoin } from 'rxjs';
import { map, debounceTime, mergeMap, shareReplay, catchError } from 'rxjs/operators';
import { QueryDialogComponent } from '../../main-news/news-query/query-dialog/query-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { EntityaskComponent } from 'src/app/admin/entityask/entityask.component';
import { AuthService } from 'src/app/service/auth.service';
import { ConfigService } from 'src/app/service/config.service';

/**
 *
 */
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-menu-search',
  templateUrl: './menu-search.component.html',
  styleUrls: ['./menu-search.component.scss']
})
export class MenuSearchComponent {

  /**
   * input type component
   */
  @Input('component') component: string;

  /**
   * triggering MatAutocomplete panel for input
   */
  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputAutoComplete: MatAutocompleteTrigger;

  /**
   * needed to blur input on dialog open
   */
  @ViewChild('inputAutoComplete') inputElement: ElementRef;

  /**
   * query parameters url
   */
  params: any;

  /**
   * data from the search request
   */
  items$: Observable<YukkApi>;

  /**
   * data from the pnes search request
   */
  itemsPNE: any;

  /**
   * saved entities from previous searches
   */
  previousSearches = [];

  /**
   * form control search
   */
  mysearch: UntypedFormControl = new UntypedFormControl();

  /**
   * query params entities url
   * @todo we dont need this
   */
  keyid: any;

  /**
   * loading
   */
  loading: boolean;

  /**
   * loading pnes
   */
  loadingPNE: boolean;

  /**
   * ready to start
   */
  mystart: boolean;

  /**
   * empty input field
   */
  emptyInput = true;

  /**
   * colors array
   */
  colors = ['#feb236', '#6b5b95', '#d64161', '#ff7b25', '#878f99', '#b5e7a0', '#eca1a6', '#bdcebe'];

  /**
   * label of the search
   */
  label = 'Search by Name, ISIN or D-U-N-S Number';

  /**
   * show more entities from a group
   */
  showMoreEntities = {};

  /**
   * show more pnes from a group
   */
  showMorePNEs = false;

  /**
   * color theme
   */
  theme = 'dark';

  /**
   * queryParams and valueChanges subscribe
   */
  constructor(
    private yukkApi: YukkApi,
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    public location: Location,
    public dialog: MatDialog,
    public auth: AuthService,
    public config: ConfigService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
      if (params.theme) {
        this.theme = params.theme;
      } else if (this.config.appConfig.setup.useStorage) {
        this.theme = localStorage.getItem('theme');
      } else {
        this.theme = 'dark';
      }
      if (this.theme === 'yukka') {
        this.theme = 'dark';
      }
      params.keyid ? this.keyid = params.keyid.split(',') : this.keyid = [];
      if (this.routing.isMobile()) {
        this.label = 'Search...';
      }
      this.showMoreEntities = {};
      this.showMorePNEs = false;
    });
    this.mysearch.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(value => {
        if ( value && ( value.length > 1 ) ) {
          this.emptyInput = false;
          this.mystart = true;
          this.loading = true;
          this.loadingPNE = true;
          const reqEntity = this.yukkApi.search_meta(value, this.params).pipe(map((res) => res), catchError(e => of({entity_prefix_search: {hits: []}, event_search: {hits: []}, source_prefix_search: {hits: []}})));
          const reqPNE = this.yukkApi.search_pne(value, this.params).pipe(map((res) => {
            return res;
          }), catchError(e => {
            return of([]);
          }));
          this.items$ = reqEntity.pipe(map(result => {
            reqPNE.subscribe(pneRes => {
              this.itemsPNE = this.getAllPNEs(pneRes);
              this.loadingPNE = false;
            });
            if (result && (result.entity_prefix_search.hits.length === 0) && (result.event_search.hits.length === 0) && (result.source_prefix_search.hits.length === 0) && (value.charAt(0) !== '(')) {
              const words = value.split(' ').filter(item => (item !== ''));
              this.loading = false;
              let query = {};
              if (words.length < 3) {
                query = {
                  filters: [],
                  groups: [],
                  operator: 'and'
                };
                query['groups'].push({
                  filters: [{
                    field: 'text',
                    negated: false,
                    name: '',
                    input: value,
                    value: value
                  }],
                  groups: [],
                  operator: 'or'
                });
              } else if (words.length === 3) {
                query = {
                  filters: [],
                  groups: [],
                  operator: 'and'
                };
                query['groups'].push({
                  filters: [
                    {
                      field: 'text',
                      negated: false,
                      name: '',
                      input: words[0] + ' ' + words[1] + ' ' + words[2],
                      value: words[0] + ' ' + words[1] + ' ' + words[2]
                    },
                    {
                      field: 'text',
                      negated: false,
                      name: '',
                      input: words[0] + ' ' + words[1],
                      value: words[0] + ' ' + words[1]
                    }
                  ],
                  groups: [],
                  operator: 'or'
                });
              } else if (words.length > 3) {
                query = {
                  filters: [],
                  groups: [],
                  operator: 'or'
                };
                query['groups'].push({
                  filters: [{
                    field: 'text',
                    negated: false,
                    name: '',
                    input: value,
                    value: value
                  }],
                  groups: [],
                  operator: 'or'
                });
                words.forEach((item, index) => {
                  if (index !== 0) {
                    query['groups'].push({
                      filters: [
                        {
                          field: 'word',
                          negated: false,
                          name: '',
                          input: words[0],
                          value: words[0]
                        },
                        {
                          field: 'word',
                          negated: false,
                          name: '',
                          input: item,
                          value: item
                        }
                      ],
                      groups: [],
                      slop: 5,
                      operator: 'near'
                    });
                  }
                });
              }
              return Object.assign({}, result, {
                parse_boolean_query: query
              });
            } else {
              this.loading = false;
              if (result && result.parse_boolean_query && (!result.parse_boolean_query.groups || (result.parse_boolean_query.groups && (result.parse_boolean_query.groups.length === 0)))) {
                const query = {
                  filters: [],
                  groups: [],
                  operator: 'and'
                };
                query['groups'].push({
                  filters: result.parse_boolean_query.filters,
                  groups: [],
                  operator: result.parse_boolean_query.operator
                });
                return Object.assign({}, result, {
                  parse_boolean_query: query
                });
              } else {
                return result;
              }
            }
          }), shareReplay());
        } else {
          this.emptyInput = true;
          this.mystart = false;
          this.loading = false;
          this.loadingPNE = false;
        }
      });
  }

  /**
   * runs on input focus to get & display previous searches
   */
  onFocus() {
    if ( this.mysearch.value && ( this.mysearch.value.length > 1 ) ) {
      this.mystart = true;
      this.loading = false;
    } else {
      this.mystart = false;
      this.loading = true;
      this.auth.getSearchActivity(10).subscribe(result => {
        // @ts-ignore
        const entities = result.filter(item => {
          return item.clicked.field === 'entity';
        }).map(el => {
          return el.clicked.value;
        });
        this.yukkApi.getEntitiesInfo(entities).subscribe(result2 => {
          const entitiesObjects = Object.values(result2).filter(element => {
            return element !== null;
          }).map(el => {
            return {
              entity: el
            };
          });
          this.previousSearches = entitiesObjects;
          this.loading = false;
        });
      });
    }
  }

  /**
   * redirect specific select type
   */
  goSelect(option) {

    if (option === 'search') {

      this.mystart = false;
      this.mysearch.reset();
      this.inputElement.nativeElement.blur();
      this.queryDialog();

    } else if (option === 'request') {

      this.mystart = false;
      this.mysearch.reset();
      this.inputElement.nativeElement.blur();
      this.requestEntityDialog();

    } else if (option !== undefined) {

      this.mystart = false;
      this.mysearch.reset();
      this.inputElement.nativeElement.blur();
      // this.search.nativeElement.blur()
      this.items$ = empty();
      this.previousSearches = [];
      let type = null;
      let id = null;
      // let query = null
      let ua_value = '';
      let ua_type = '';
      let path = 'news/market/';
      let currentView = location.pathname.split('/')[4];
      if (option.type === 'ask4') {
        this.dialog.open(EntityaskComponent, {
          data: option.value
        });
        ua_type = 'request';
        ua_value = option.value;
        return null;
      } else if (option.type === 'searchValue') {
        this.dialog.open(QueryDialogComponent, {
          data: option
        });
        ua_type = 'search_value';
        ua_value = JSON.stringify(option.value);
        return null;
      } else if (option.type === 'searchQuery') {
        this.dialog.open(QueryDialogComponent, {
          data: option
        });
        ua_type = 'search_query';
        ua_value = JSON.stringify(option.query);
        return null;
      }
      let iparams = {};
      if (option.entity) {
        if (!currentView || currentView === 'main' || currentView === 'chart2' || currentView === 'scanner') {
          currentView = 'chart';
        }
        type = option.entity.type;
        id = option.entity.alpha_id;
        ua_type = 'entity';
        ua_value = type + ':' + id;
        iparams = {
          id: id,
          type: type,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          factuality: null,
          temporality: null,
          tag: null,
          participant: null,
          newstype: null
        };
      } else if (option.type === 'pne') {
        if (!currentView || currentView === 'main' || currentView === 'chart2' || currentView === 'scanner') {
          currentView = 'chart';
        }
        type = option.type;
        id = option.pne.alpha_id;
        const formattedPNE = {
          surface: option.pne.surface,
          compound_key: option.pne.compound_key
        };
        ua_value = JSON.stringify(formattedPNE);
        ua_type = 'potential_named_entity';
        iparams = {
          id: id,
          type: type,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          factuality: null,
          temporality: null,
          tag: null,
          participant: null,
          newstype: null
        };
      } else if (option.type === 'query') {
        if (!currentView || currentView === 'main' || currentView === 'scanner') {
          currentView = 'chart';
        }
        type = option.type;
        ua_value = JSON.stringify(option.query);
        ua_type = 'search_query';
        this.auth.query = {
          name: option.query,
          query: option.query
        };
        if (this.config.appConfig.setup.useStorage) {
          localStorage.setItem('search', JSON.stringify(this.auth.query));
        }
        path = 'news/search/';
        iparams = {
          type: null,
          id: Math.random(),
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          factuality: null,
          temporality: null,
          tag: null,
          participant: null,
          newstype: null
        };
      } else if (option.type === 'source') {
        if (!currentView || currentView === 'main' || currentView === 'chart2' || currentView === 'scanner') {
          currentView = 'radar';
        }
        type = option.type;
        id = option.option.sources[0].id;
        ua_value = option.option.name;
        ua_type = 'source';
        iparams = {
          id: id,
          type: type,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          factuality: null,
          temporality: null,
          tag: null,
          participant: null,
          sort: 'trend',
          newstype: null
        };
        path = 'news/market/';
      } else if ((option.type === 'super_event') || (option.type === 'event') || (option.type === 'sub_event')) {
        if (!currentView || currentView === 'main' || currentView === 'chart2' || currentView === 'scanner') {
          currentView = 'radar';
        }
        type = option.option.type;
        id = option.option.id;
        ua_value = option.option.name;
        ua_type = 'event';
        iparams = {
          id: id,
          type: type,
          eventid: id,
          eventype: type,
          scoreevent: null,
          flagevent: null,
          factuality: null,
          temporality: null,
          tag: null,
          participant: null,
          sort: 'trend',
          newstype: null
        };
        path = 'news/market/';
      }
      if (this.routing.isWidget()) {
        const tool = location.pathname.split('/')[2];
        path = '/widget/' + tool;
        if (tool === 'portfolio') {
          path = '/widget/chart';
        }
        currentView = '';
      }
      iparams['newsaudit'] = null;
      iparams['portfolioId'] = null;
      iparams['groupId'] = null;
      iparams['eventsFromScanner'] = null;
      iparams['score'] = null;
      if (!currentView) {
        currentView = '';
      }
      const fullPath = path + currentView;
      this.router.navigate([fullPath], {
        relativeTo: this.route,
        queryParams: iparams,
        queryParamsHandling: 'merge'
      });
      if (this.auth.featureFlags.activityLogging) {
        this.auth.userActivity({
          environment: this.config.appConfig.routing.project,
          url: this.router.url,
          activity_type: 'search_bar_click',
          clicked: {
            field: ua_type,
            value: ua_value
          }
        }).subscribe();
      }

    }

  }

  /**
   * open dialog query
   */
  queryDialog() {
    if (this.component === 'menuabove') {
      this.dialog.open(QueryDialogComponent);
    }

  }

  /**
   * open dialog request entity
   */
  requestEntityDialog() {
    if (this.component === 'menuabove') {
      this.dialog.open(EntityaskComponent, {
        data: ''
      });
    }
  }

  /**
   *
   */
  iMatch(entity) {
    if (entity.matched_form) {
      return entity.name.charAt(0).toLowerCase() !== entity.matched_form.charAt(0);
    }
  }

  /**
   * format big numbers >999 into K, M, ...
   */
  numberFormat(num) {
    // @ts-ignore
    return Intl.NumberFormat('en', { notation: 'compact' }).format(num);
  }

  /**
   * get list of all pnes
   */
  getAllPNEs(items) {
    const pneList = [];
    items.forEach(item => {
      item.compound_keys.forEach((el, index) => {
        pneList.push({
          surface: item.surface,
          count: item.counts[index],
          compound_key: el,
          type: el.split(':')[1].split('.')[0],
          id: el.split(':')[1].split('.')[1],
          alpha_id: el.split(':')[1]
        });
      });
    });
    return pneList;
  }

  /**
   * show more than 5 entities for a particular group
   */
  expandEntitiesGroup(i) {
    this.showMoreEntities[i] = !this.showMoreEntities[i];
  }

  /**
   * check whether to show more than 5 entities for a particular group
   */
  checkShowMoreEntities(i) {
    if (this.showMoreEntities[i]) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * show more than 5 pnes
   */
  expandPNEsGroup() {
    this.showMorePNEs = !this.showMorePNEs;
  }

  /**
   * check whether to show more than 5 pnes
   */
  checkShowMorePNEs() {
    if (this.showMorePNEs) {
      return 1000;
    } else {
      return 5;
    }
  }

}
