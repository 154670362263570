<div class="chat-main">

    <div class="chat-header">
        <span>{{headerText}}</span>
    </div>

    <div class="chat-container">

        <div class="top-fade" [class.theme-light]="params?.theme === 'light'"></div>

        <div class="chat-output">

            <div class="chat-output-inner">

                <ng-container *ngFor="let message of chatHistory; let i = index;">

                    <div class="chat-question" [innerHTML]="message[0]"></div>

                    <div class="chat-response" *ngIf="(i !== 0) || !loading" [innerHTML]="message[1]"></div>

                    <div class="chat-response" *ngIf="(i === 0) && loading">
                        <mat-spinner [diameter]="50"></mat-spinner>
                    </div>

                </ng-container>

            </div>

        </div>

        <div class="chat-input">

            <div class="bottom-fade" [class.theme-light]="params?.theme === 'light'"></div>

            <form class="input-box" (ngSubmit)="onSubmit($event)">

                <input matInput [(ngModel)]="chatInput" type="email" placeholder="Type a message..." name="chat_input">

                <button mat-raised-button type="submit">
                    <mat-icon>send</mat-icon>
                </button>

            </form>

        </div>

    </div>

</div>
