import { Component, ViewEncapsulation, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { NewsletterService } from 'src/app/service/newsletter.service';
import { RoutingService } from 'src/app/service/routing.service';
import { expand, takeWhile, takeUntil, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

@Component({
  // encapsulation: ViewEncapsulation.ShadowDom,
  selector: 'app-newsletter-preview',
  templateUrl: './newsletter-preview.component.html',
  styleUrls: ['./newsletter-preview.component.scss']
})
export class NewsletterPreviewComponent implements OnChanges, OnDestroy {

  newsletter: any;
  newsletterPreview: string;
  loading: boolean;
  errorMessage: boolean;

  params: any;
  previousValue: any;

  selectedArticles = [];

  scrollIndex: number;
  readyscroll: boolean;

  numSections: number;
  initialNewsletterCopy: any;

  newsletterContent = [];

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    public newsletterService: NewsletterService,
    public routing: RoutingService,
  ) {

    setTimeout(() => {
      document.body.addEventListener('click', (e) => {
        if (e.target && e.target['value'] && e.target['value'].includes('{')) {
          this.dismissNews(JSON.parse(e.target['value']));
        }
      }, false);
    }, 0);

    this.route.queryParams.subscribe(params => {
      this.params = JSON.parse(JSON.stringify(params));
      if (this.routing.reFresh(params, this.previousValue, ['id', 'rdn'])) {
        if (params.id && (params.id !== 'create')) {
          this.loading = true;
          this.errorMessage = false;
          this.selectedArticles = [];
          this.scrollIndex = 0;
          this.readyscroll = false;
          this.numSections = null;
          this.newsletterContent = [];
          this.newsletterPreview = '';
          this.initialNewsletterCopy = null;
          this.auth.previewNewsletter(this.params.id, 0).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            const resCopy = JSON.parse(JSON.stringify(res));
            this.initialNewsletterCopy = JSON.parse(JSON.stringify(res));
            this.numSections = resCopy.num_sections;
            this.newsletterContent.length = resCopy.num_sections;
            this.newsletterContent.forEach((el, index) => {
              this.newsletterContent[index] = [];
            });
            this.auth.previewNewsletter(this.params.id, this.scrollIndex).pipe(
              expand(() => {
                return this.auth.previewNewsletter(this.params.id, this.scrollIndex);
              }),
              takeWhile(res2 => {
                if (!res2['html'].includes('No new information') || (this.scrollIndex === (this.numSections - 1))) {
                  this.loadPreview(this.scrollIndex);
                }
                return (this.scrollIndex < (this.numSections - 1));
              }),
              takeUntil(this.ngUnsubscribe)
            ).subscribe(() => {
              this.scrollIndex++;
            });
            // if (resCopy.html.includes('No new information')) {
            //   this.auth.previewNewsletter(this.params.id, this.scrollIndex).pipe(
            //     expand(() => {
            //       return this.auth.previewNewsletter(this.params.id, this.scrollIndex);
            //     }),
            //     takeWhile(res2 => {
            //       if (!res2['html'].includes('No new information') || (this.scrollIndex === (this.numSections - 1))) {
            //         this.loadPreview(this.scrollIndex);
            //       }
            //       return (res2['html'].includes('No new information') && (this.scrollIndex !== (this.numSections - 1)));
            //     })
            //   ).subscribe(() => {
            //     this.scrollIndex++;
            //   });
            // } else {
            //   this.loadPreview(0);
            // }
          });
        } else if (params.id && (params.id === 'create')) {
          this.readyscroll = true;
          this.loading = false;
          this.errorMessage = false;
          this.selectedArticles = [];
          this.scrollIndex = 0;
          this.numSections = null;
          this.newsletterContent = [];
          this.newsletterPreview = '';
        }
      }
      this.previousValue = params;
    });

  }

  ngOnChanges() {
    this.newsletter = this.newsletterService.newsletter;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(); //  Unsubscribe from observables.
    this.ngUnsubscribe.complete(); // Unsubscribe from ngUnsubscribe.
  }

  dismissNews(item) {
    let check = false;
    const filteredArticles = this.selectedArticles.filter(news => {
      if (news.news === item.news) {
        check = true;
      }
      return news.news !== item.news;
    });
    if (check) {
      this.selectedArticles = filteredArticles;
    } else {
      this.selectedArticles.push(item);
    }
  }

  dismissManyNews() {
    if (this.selectedArticles.length !== 0) {
      const newsletterId = (this.routing.isNewsletter() && this.previousValue && this.previousValue.id) ? this.previousValue.id : null;
      if (window.confirm('Do you want to delete all selected articles from the newsletter?')) {
        const newsIds = this.selectedArticles.map(el => el.news);
        const uniqueSections = [...new Set(this.selectedArticles.map(item => item.section))];
        this.auth.newsletterDismissNews(newsIds, newsletterId).subscribe(() => {
          this.selectedArticles = [];
          uniqueSections.forEach(section => {
            this.loadPreview(Number(section));
          });
        });
      }
    }
  }

  loadPreview(sectionIndex) {
    this.readyscroll = this.scrollIndex === 0;
    let requestNewsletter;
    if ((sectionIndex === 0) && this.initialNewsletterCopy) {
      requestNewsletter = of(this.initialNewsletterCopy);
    } else {
      requestNewsletter = this.auth.previewNewsletter(this.params.id, sectionIndex);
    }
    requestNewsletter.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.initialNewsletterCopy = null;
      const doc = new DOMParser().parseFromString(res.html, 'text/html');
      const newsLinkRowArray = doc.getElementsByClassName('news-link-row');
      const ids = [];
      Array.from(newsLinkRowArray).forEach((row, index) => {
        let newsId = '';
        if (row.children[0]['href']) {
          row.children[0]['href'].split('?')[1].split('&').forEach(el => {
            if (el.split('=')[0] === 'news') {
              newsId = el.split('=')[1];
            }
          });
          ids.push(newsId);
        }
      });
      const newsRowArray = doc.getElementsByClassName('news-row');
      Array.from(newsRowArray).forEach((row, index) => {
        row['style'].position = 'relative';
        row.innerHTML = `<input type="checkbox" value='{"news":"` + ids[index] + `","section":"` + sectionIndex + `"}' style='position: absolute; left: -35px; accent-color: #1E816C;'>` + row.innerHTML;
      });
      const newsletterSectionArray = doc.getElementsByClassName('newsletter-section');
      const newsletterBody = doc.getElementsByClassName('body');
      const outerElementsBeforeSection = [];
      const outerElementsAfterSection = [];
      let isBeforeSection = true;
      this.newsletterContent[sectionIndex] = [];
      Array.from(newsletterBody[0].children).forEach(el => {
        if (el.className.includes('newsletter-section')) {
          isBeforeSection = false;
          this.newsletterContent[sectionIndex].push(el);
        } else if (el.innerHTML.includes('No new information')) {
          isBeforeSection = false;
        } else if (isBeforeSection) {
          outerElementsBeforeSection.push(el);
        } else {
          outerElementsAfterSection.push(el);
        }
      });
      let bodyInnerHTML = '';
      outerElementsBeforeSection.forEach(el => {
        bodyInnerHTML += el.innerHTML;
      });
      this.newsletterContent.forEach(section => {
        section.forEach(el => {
          bodyInnerHTML += el.innerHTML;
        });
      });
      outerElementsAfterSection.forEach(el => {
        bodyInnerHTML += el.innerHTML;
      });
      newsletterBody[0].innerHTML = bodyInnerHTML;
      const checkboxes = doc.getElementsByTagName('input');
      for (let i = 0; i < checkboxes.length; i++) {
        const newsId = JSON.parse(checkboxes[i]['value']).news;
        const isChecked = this.selectedArticles.map(article => article.news).includes(newsId);
        if (isChecked) {
          checkboxes[i].setAttribute('checked', 'checked');
        }
      }
      this.newsletterPreview = doc.documentElement.innerHTML;
      this.readyscroll = true;
      this.loading = false;
    }, error => {
      this.readyscroll = true;
      this.loading = false;
      this.errorMessage = true;
    });

  }

  inScrolled() {
    if (this.scrollIndex < (this.numSections - 1)) {
      this.scrollIndex++;
    }
    this.loadPreview(this.scrollIndex);
  }

}
