import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { ConfigService } from 'src/app/service/config.service';

/**
* redirect to specific portfolio if no one is selected and store the data in the auth service
*/

@Injectable()
export class FolioGuard  {
  constructor(
    private auth: AuthService,
    public config: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    if ((state.url.split('?')[0] === '/cockpit/news/portfolio/scanner') && (this.auth.featureFlags.defaultPortfolioView !== 'scanner')) {
      this.router.navigate(['/cockpit/news/portfolio/' + this.auth.featureFlags.defaultPortfolioView], {
        replaceUrl: true
      });
    } else {
      return this.auth.portFolios().pipe(map(folios => {
        this.auth.folios = [];
        this.auth.folio = undefined;
        if (folios[0]) {
          this.auth.folios = folios;
          if (!state.root.queryParams.id && !state.root.queryParams.name) {
            const sortedFolios = JSON.parse(JSON.stringify(folios));
            sortedFolios.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            this.auth.folio = sortedFolios[0];
            this.router.navigate([state.url.split('?')[0]], {
              queryParams: {
                id: this.auth.folio.uid,
                news: state.root.queryParams.news,
                highlight: state.root.queryParams.highlight,
                time: state.root.queryParams.time,
                filter: state.root.queryParams.filter,
                lang: state.root.queryParams.lang ? state.root.queryParams.lang : 'en,de',
                feed: state.root.queryParams.feed ? state.root.queryParams.feed : 'non_professional,online,print,professional',
                ranks: state.root.queryParams.ranks,
                // categories: state.root.queryParams.categories ? state.root.queryParams.categories : 'gent,gedu,ciprof,glife,cmarkr,gpersf,gsci,gspo,gwea',
                categories: state.root.queryParams.categories ? state.root.queryParams.categories : '',
                continents: state.root.queryParams.continents,
                countries: state.root.queryParams.countries,
                event_ids: state.root.queryParams.event_ids,
                tutorial: state.root.queryParams.tutorial,
                sort: !(this.auth.scorelabSettings.defaultScore === 'sentiment') ? 'score' : '-senti',
              },
              // replaceUrl: true,
            });
          } else if ( !state.root.queryParams.id && state.root.queryParams.name ) {
            this.auth.folio = {
              email: null,
              uid: null,
              name: state.root.queryParams.name,
              content: state.root.queryParams.entities.split(',')
            };
          } else {
            if (!state.root.queryParams.portfolioId) {
              let idExists = false;
              folios.map(folio => {
                if (folio.uid === state.root.queryParams.id) {
                  this.auth.folio = folio;
                  idExists = true;
                }
              });
              if (!idExists) {
                const sortedFolios = JSON.parse(JSON.stringify(folios));
                sortedFolios.sort((a, b) => {
                  return a.name.localeCompare(b.name);
                });
                this.auth.folio = sortedFolios[0];
                this.router.navigate([state.url.split('?')[0]], {
                  queryParams: {
                    id: this.auth.folio.uid,
                    news: state.root.queryParams.news,
                    highlight: state.root.queryParams.highlight,
                    time: state.root.queryParams.time,
                    filter: state.root.queryParams.filter,
                    lang: state.root.queryParams.lang ? state.root.queryParams.lang : 'en,de',
                    feed: state.root.queryParams.feed ? state.root.queryParams.feed : 'non_professional,online,print,professional',
                    ranks: state.root.queryParams.ranks,
                    // categories: state.root.queryParams.categories ? state.root.queryParams.categories : 'gent,gedu,ciprof,glife,cmarkr,gpersf,gsci,gspo,gwea',
                    categories: state.root.queryParams.categories ? state.root.queryParams.categories : '',
                    continents: state.root.queryParams.continents,
                    countries: state.root.queryParams.countries,
                    event_ids: state.root.queryParams.event_ids,
                    tutorial: state.root.queryParams.tutorial,
                    sort: !(this.auth.scorelabSettings.defaultScore === 'sentiment') ? 'score' : '-senti',
                  },
                  // replaceUrl: true,
                });
              }
            } else {
              let idExists = false;
              folios.map(folio => {
                if (folio.uid === state.root.queryParams.portfolioId) {
                  this.auth.folio = folio;
                  idExists = true;
                }
              });
              if (!idExists) {
                alert('The link includes a portfolio that is not accessible. You will be redirected to your cockpit.');
                const sortedFolios = JSON.parse(JSON.stringify(folios));
                sortedFolios.sort((a, b) => {
                  return a.name.localeCompare(b.name);
                });
                this.auth.folio = sortedFolios[0];
                this.router.navigate([state.url.split('?')[0]], {
                  queryParams: {
                    portfolioId: this.auth.folio.uid,
                    // id: state.root.queryParams.id,
                    id: null,
                    type: state.root.queryParams.type,
                    news: state.root.queryParams.news,
                    highlight: state.root.queryParams.highlight,
                    time: state.root.queryParams.time,
                    filter: state.root.queryParams.filter,
                    lang: state.root.queryParams.lang ? state.root.queryParams.lang : 'en,de',
                    feed: state.root.queryParams.feed ? state.root.queryParams.feed : 'non_professional,online,print,professional',
                    ranks: state.root.queryParams.ranks,
                    // categories: state.root.queryParams.categories ? state.root.queryParams.categories : 'gent,gedu,ciprof,glife,cmarkr,gpersf,gsci,gspo,gwea',
                    categories: state.root.queryParams.categories ? state.root.queryParams.categories : '',
                    continents: state.root.queryParams.continents,
                    countries: state.root.queryParams.countries,
                    event_ids: state.root.queryParams.event_ids,
                    tutorial: state.root.queryParams.tutorial,
                    sort: !(this.auth.scorelabSettings.defaultScore === 'sentiment') ? 'score' : '-senti',
                  },
                  // replaceUrl: true,
                });
              }
            }
          }
        }
        return true;
      }));
    }
  }
}
